import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Element } from 'react-scroll';
import cn from 'classnames';

import { WithTranslationType, withTranslation } from '../../server/i18n';
import { IApplicationFormErrors } from '../../../types/signup';

import Spinner from '../../components/Spinner';

import './application-submit-button.scss';

interface Props extends WithTranslationType {
  errors: IApplicationFormErrors;
  loading: boolean;
  scrollTo: Function;
}

@observer
class ApplicationSubmitButton extends Component<Props, null> {
  @computed get hasErrors(): boolean {
    const { errors } = this.props;
    return Object.values(errors).some(i => i);
  }

  render(): JSX.Element {
    const { hasErrors } = this;
    const { errors, loading, scrollTo, t } = this.props;
    let errorMessage: string | JSX.Element = t('signup_errors-in-form');

    if (errors.serverValidation) {
      scrollTo('errorMessageBox');
      errorMessage = t('signup_error-occurred-while-submitting-application');
    }

    return (
      <div className="application-submit-button__wrapper">
        <div className="text-center">
          <button
            className={cn('button button--submit-btn full-width-mobile', {
              disabled: loading || hasErrors,
              loading: loading && !hasErrors,
            })}
            data-qa="signup-submit-button"
            data-testid="js-signup-submit-button"
            formNoValidate
            id="js-signup-submit-button"
            type="submit"
          >
            {loading && !hasErrors ? (
              <Spinner />
            ) : (
              <span>{t('signup_submit-your-application')}</span>
            )}
          </button>
        </div>
        {hasErrors && (
          <Element name="errorMessageBox">
            <div
              className="application-submit-button__error-box"
              data-qa="error-box-message"
              data-testid="js-error-box-message"
            >
              {errorMessage}
            </div>
          </Element>
        )}
      </div>
    );
  }
}

export default withTranslation('signup')(ApplicationSubmitButton);
