import React, { SetStateAction, useRef } from 'react';
import cn from 'classnames';

import { useOutsideClick } from '../../hooks/useOutsideClick';

import './side-panel.scss';
import cross from '../../../public/static/images/icons/cross.svg';

interface Props {
  children: JSX.Element;
  setShowPanel: React.Dispatch<SetStateAction<any>>;
  show: boolean;
}

const SidePanel = ({ children, setShowPanel, show }: Props): JSX.Element => {
  const ref = useRef();

  useOutsideClick(ref, () => {
    if (show) {
      setShowPanel(false);
    }
  });

  const onClick = () => {
    setShowPanel(false);
  };

  return (
    <div className={cn('side-panel', { hide: !show })} ref={ref}>
      <button className="close-btn" onClick={onClick} type="button">
        <img alt="cross" src={cross} />
      </button>
      {children}
    </div>
  );
};

export default SidePanel;
