import React, { FC } from 'react';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import './footer.scss';

const Footer: FC<WithTranslationType> = ({ t }: WithTranslationType) => {
  return (
    <div className="footer columns">
      <div className="column is-12-mobile is-4-tablet is-offset-4-tablet align-center">
        <p>{t('portal-footer_copyright-text')}</p>
      </div>
    </div>
  );
};

export default withTranslation('portal-footer')(Footer);
