import React from 'react';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { withFormsy } from 'formsy-react';

interface CityInputProps {
  cities: {
    citiesByCountry: [
      {
        id: string;
        name: string;
      },
    ];
    defaultCity: {
      id: string;
      name: string;
    };
    selectDefaultCity: boolean;
  };
  className: string;
  placeholder: string;
  selectDefaultCity: boolean;
  setValue?: Function;
  value: string;
}

@inject(({ store: { cities } }) => ({
  cities,
}))
@observer
class CityInput extends React.Component<CityInputProps> {
  @autobind
  @action('onSelectCity - CityInput')
  onSelectCity(event: React.ChangeEvent<HTMLSelectElement>): void {
    const { setValue } = this.props;
    const { value } = event.currentTarget;

    setValue(value);
  }

  render(): JSX.Element {
    const { onSelectCity } = this;
    const { cities, className, placeholder, setValue, value } = this.props;
    const { citiesByCountry, defaultCity, selectDefaultCity } = cities;

    if (selectDefaultCity) {
      if (setValue && defaultCity) {
        setValue(defaultCity.id);
      }

      return (
        <select
          className={className}
          data-qa="select-city"
          data-testid="select-city"
          value={value}
          id="select-city"
        >
          <option data-qa={defaultCity.id} key={defaultCity.id} value={defaultCity.id}>
            {defaultCity.name}
          </option>
        </select>
      );
    }

    return (
      <select
        className={className}
        data-qa="select-city"
        data-testid="select-city"
        id="select-city"
        onChange={onSelectCity}
        value={value}
      >
        <option hidden>{placeholder}</option>
        {citiesByCountry.map(city => (
          <option data-qa={city.id} data-testid={city.id} key={city.id} value={city.id}>
            {city.name}
          </option>
        ))}
      </select>
    );
  }
}

export default withFormsy(CityInput);
