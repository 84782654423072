export enum TransportTypeEnum {
  DRIVING = 'driving',
  PUBLIC_TRANSPORT = 'transit',
}

export enum GenderEnum {
  FEMALE = 'female',
  MALE = 'male',
}

export enum TreatmentToBothGendersEnum {
  NO = 'no',
  YES = 'yes',
}

export enum QualificationEnum {
  CERTIFIED = 'certified',
  NOT_CERTIFIED = 'not-certified',
  STUDENT = 'student',
}
