import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { dateFormat } from '../../helpers';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { useStore } from '../../contexts/storeContext';


const SingleBookingOtherHeroes: FC<WithTranslationType> = observer(({ t }) => {
  const {
    singleBooking: {
      booking,
      isPastBooking,
    },
  } = useStore();

  return !isPastBooking && booking && booking.otherHeroes.length > 0 ? (
    <div className="single-booking__other-heroes">
      <div className="single-booking__other-heroes-header">
        <h3 className="single-booking__other-heroes-title">{t('single-booking_other-heroes-section-title')}</h3>
      </div>
      {booking.otherHeroes.map(hero => {
        return (
          <div className="single-booking__summary columns is-multiline is-mobile single-booking__other-hero" key={hero.id}>
            <div className="single-booking__column">
              <div className="single-booking__therapist-name">{hero.name}</div>
              {hero.mobile && (
                <div className="single-booking__hero-contact">
                  <a className="button button--blue single-booking__hero-contact-button" href={`tel:+${hero.mobile}`}>
                    {t('single-booking_other-heroes-contact')}
                  </a>
                </div>
              )}
            </div>
            <div className="single-booking__column single-booking__column--align-right">
              {t('single-booking_other-heroes-start-time-header', {startTime: dateFormat(hero.startsAt, 'HH:mm', booking.timezone)})}
            </div>
          </div>
        );
      })}
    </div>
  ): null;
});

export default withTranslation('single-booking')(SingleBookingOtherHeroes);
