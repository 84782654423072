import React from 'react';
import Router from 'next/router';
import { action } from 'mobx';
import { inject, observer } from 'mobx-react';

import autobind from 'autobind-decorator';
import { Instance } from 'mobx-state-tree';
import { withUser } from '../../../hocs/withUser';
import { withTranslation, WithTranslationType } from '../../../server/i18n';

import Link from '../../Link';
import Menu from './Menu';
import MobileMenu from './MobileMenu';

import logoImage from '../../../../public/static/images/urban-logo-hero.svg';

import './index.scss';
import { I18nUtils } from '../../../stores/i18nUtils';

interface PortalNavbarProps extends WithTranslationType {
  activeMenuItem: string;
  bookings: {
    bookings: [object];
    getBookings: Function;
    isLoading: boolean;
    pastBookings: [object];
    unconfirmedBookings: [object];
    upcomingBookings: [object];
  };
  i18nUtils: Instance<typeof I18nUtils>;
  session: {
    isActive: boolean;
    city: {
      telephoneHuman: string;
      telephoneLink: string;
    };
    getUser: Function;
    hideCommunity: boolean;
    hideEarnings: boolean;
    hideReferral: boolean;
    hideStatements: boolean;
    isLoggedIn: boolean;
    logout: Function;
    setToken: Function;
    setUTMCookies: Function;
    user: {
      id: number;
      name: string;
    };
  };
}

@inject(({ store: { bookings, i18nUtils, session } }) => ({
  bookings,
  i18nUtils,
  session,
}))
@observer
class PortalNavbar extends React.Component<PortalNavbarProps, null> {
  @autobind
  @action('handleLogout - BookingsList')
  handleLogout(): void {
    const { i18nUtils, session } = this.props;
    session.logout();

    Router.push(`/${i18nUtils.locale}/`);
  }

  render(): React.ReactNode {
    const { activeMenuItem, session, t } = this.props;

    return (
      <div className="portal-navbar">
        <div className="include pull-right">
          <Link
            className="portal-navbar__btn portal-navbar__btn--promote-btn"
            prefetch={false}
            route="/promote-profile"
          >
            {t('portal-navbar_promote')}
          </Link>
          <button
            className="portal-navbar__btn portal-navbar__btn--loggout-btn hidden-xs"
            onClick={this.handleLogout}
            id="js-headerLoginButton"
            type="button"
          >
            {t('portal-navbar_logout-button')}
          </button>
        </div>
        <Link className="portal-navbar__logo" route="/">
          <img alt={t('portal-navbar_logo-alt')} src={logoImage} />
        </Link>
        {session.isActive && (
          <React.Fragment>
            <Menu
              activeMenuItem={activeMenuItem}
              hideCommunity={session.hideCommunity}
              hideReferral={session.hideReferral}
              hideStatements={session.hideStatements}
            />
            <MobileMenu
              activeMenuItem={activeMenuItem}
              hideCommunity={session.hideCommunity}
              hideReferral={session.hideReferral}
              hideStatements={session.hideStatements}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation('portal-navbar')(withUser(PortalNavbar));
