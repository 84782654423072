import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import cn from 'classnames';

import Spinner from '../../components/Spinner';

interface Props {
  errors: any;
  submitted: boolean;
  loading: boolean;
  t: (param: string) => string;
}

@observer
class SubmitButton extends Component<Props, null> {
  @computed get hasErrors(): boolean {
    const { errors } = this.props;
    return Object.values(errors).some(i => i);
  }

  render(): JSX.Element {
    const { hasErrors } = this;
    const { errors, loading, submitted, t } = this.props;
    let errorMessage: string | JSX.Element;

    if (errors.serverErrorCode === 'FORGOT_DETAILS_NOT_RECOGNISED_ERROR') {
      errorMessage = t('forgot-password_incorrect-credentials');
    } else {
      errorMessage = t('forgot-password_error-occurred-while-log-in');
    }

    return (
      <div className="forgot-password-section__submit-btn-wrapper">
        <div className="text-center">
          <button
            className={cn('button button--submit-btn full-width-mobile', {
              disabled: loading || hasErrors || submitted,
              loading: loading && !hasErrors,
            })}
            data-testid="js-forgot-password-submit-button"
            data-qa="forgot-password-submit-button"
            formNoValidate
            id="js-forgot-password-submit-button"
            type="submit"
          >
            {loading && !hasErrors ? <Spinner /> : <span>{t('forgot-password_submit')}</span>}
          </button>
        </div>
        {hasErrors ? (
          <div
            className="form__error-box"
            data-qa="error-box-message"
            data-testid="js-error-box-message"
          >
            {errorMessage}
          </div>
        ) : (
          submitted && (
            <div className="form__success-box">{t('forgot-password_password-email-sent')}</div>
          )
        )}
      </div>
    );
  }
}

export default SubmitButton;
