import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import Checkbox from '../../components/Checkbox';

interface Props {
  checked: boolean;
  id: string;
  labelTransKey: string;
  name: string;
  onChangeSpeciality: Function;
  onChangeVertical: (event: ChangeEvent) => void;
  selectedTreatments: {
    beauty: string[];
    fitness: string[];
    lifestyle: string[];
    massage: string[];
    medical: string[];
  };
  t: (param: string) => string;
  treatmentsList: string[];
  verticalTransKey: string;
}

const VerticalWithSpecialities = ({
  checked,
  id,
  labelTransKey,
  name,
  onChangeSpeciality,
  onChangeVertical,
  selectedTreatments,
  t,
  treatmentsList,
  verticalTransKey,
}: Props) => (
  <div className="signup-form-section__verticals" key={id}>
    <Checkbox
      checked={checked}
      id={id}
      key={id}
      name={name}
      onChange={onChangeVertical}
    >
      {t(verticalTransKey)}
    </Checkbox>
    {checked && <p>{t(labelTransKey)}</p>}
    {checked && treatmentsList && (
      <Specialities
        key={name}
        name={name}
        onChangeSpeciality={onChangeSpeciality}
        selectedTreatments={selectedTreatments}
        treatmentsList={treatmentsList}
      />
    )}
  </div>
);

interface SpecialitiesProps extends WithTranslationType {
  name: string;
  onChangeSpeciality: Function;
  selectedTreatments: {
    beauty: string[];
    fitness: string[];
    massage: string[];
    medical: string[];
  };
  treatmentsList: string[];
}

export const Specialities = withTranslation('specialities')(
  observer(
    ({ name, onChangeSpeciality, selectedTreatments, t, treatmentsList }: SpecialitiesProps) => (
      <div className="signup-form-section__specialities">
        {treatmentsList.map(treatment => {
          const translationKey = `specialities_${treatment.replace(/_/g, '-').toLowerCase()}`;
          return (
            <Checkbox
              checked={selectedTreatments[name].indexOf(treatment) > -1}
              id={`js-${name}-${treatment}`}
              key={`js-${name}-${treatment}`}
              name={treatment}
              onChange={onChangeSpeciality(name)}
            >
              {t(translationKey)}
            </Checkbox>
          );
        })}
      </div>
    ),
  ),
);

export default observer(VerticalWithSpecialities);
