import React from 'react';
import { computed, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import autobind from 'autobind-decorator';
import { browserOnly } from './EnvSplit';

import './country-select.scss';

const SUPPORTED_LANG = ['en-gb', 'fr-fr'];

interface CountrySelectProps {
  i18n: {
    changeLanguage: Function;
    language: string;
  };
  i18nUtils?: {
    setLocale: Function;
  };
  t: (param: string) => string;
}

@browserOnly
@inject(({ store: { i18nUtils } }) => ({
  i18nUtils,
}))
@observer
class CountrySelect extends React.Component<CountrySelectProps, null> {
  @observable openDropdown = false;

  @autobind
  onClickOpenLanguageSelect() {
    this.openDropdown = !this.openDropdown;
  }

  @autobind
  onClickLanguageSelect(lang: string) {
    const { i18n } = this.props;

    // i18n.changeLanguage(lang);
    // i18nUtils.setLocale(lang);
    // this.openDropdown = !this.openDropdown;

    // do full page reload until we figure out all external scripts is still working without page reload
    window.location.assign(window.location.href.replace(i18n.language, lang));
  }

  @computed get languageIcon() {
    const { language } = this.props.i18n;

    return `/static/images/icons/flag_${language}.svg`;
  }

  @computed get languageOptions() {
    const { language } = this.props.i18n;

    return SUPPORTED_LANG.map(lang => {
      if (lang !== language) {
        return (
          <button
            className="country-select__option"
            onClick={() => this.onClickLanguageSelect(lang)}
            id={`js-countrySelectOption-${lang}`}
            key={lang}
            type="button"
          >
            <img
              alt="language icon"
              className="country-select__language-icon"
              src={`/static/images/icons/flag_${lang}.svg`}
            />
          </button>
        );
      }

      return null;
    });
  }

  render() {
    const { languageIcon, languageOptions, onClickOpenLanguageSelect, openDropdown } = this;

    return (
      <div className="country-select columns is-vcentered is-mobile">
        <button
          className="country-select__select-button columns is-vcentered is-mobile"
          id="js-countrySelectButton"
          onClick={onClickOpenLanguageSelect}
          type="button"
        >
          <img alt="language icon" className="country-select__language-icon" src={languageIcon} />
          <img
            alt="arrow icon"
            className="country-select__arrow-icon"
            src="/static/images/icons/chevron.png"
          />
        </button>
        {openDropdown && <div className="country-select__dropdown">{languageOptions}</div>}
      </div>
    );
  }
}

export default CountrySelect;
