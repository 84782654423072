import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { TRACKER_CATEGORY_SINGLE_BOOKING_DETAILS } from '../../helpers/trackerConstants';

import checkmarkIcon from '../../../public/static/images/checkmark.svg';
import LoadingIndicator from './LoadingIndicator';
import { useStore } from '../../contexts/storeContext';

const SingleBookingHeader: FC<WithTranslationType> = observer(({ t }) => {
  const {
    singleBooking: { booking, isConfirming, isPastBooking, confirmBooking },
    tracker,
  } = useStore();

  const onConfirmClick = (): void => {
    tracker.track('Pressed Confirm Booking Button', {
      category: TRACKER_CATEGORY_SINGLE_BOOKING_DETAILS,
      booking: booking.id,
    });

    confirmBooking();
  };

  return (
    <>
      <h1 className="single-booking__reference-text">
        {t('single-booking_reference-text', { id: booking.id })}
      </h1>
      {booking.isConfirmed ? (
        <div className="single-booking__confirmed-layout">
          <img alt="checked" src={checkmarkIcon} />
          <span>
            {isPastBooking
              ? t('single-booking_completed-booking-label')
              : t('single-booking_confirmed-booking-label')}
          </span>
        </div>
      ) : (
        <>
          <div className="columns">
            <div className="column is-12">
              <button
                className="button button--action-btn button--capitalize single-booking__confirm-button visible-xs"
                onClick={onConfirmClick}
                type="button"
              >
                {t('single-booking_confirm-button')}
              </button>
              {isConfirming && <LoadingIndicator className="visible-xs" />}
              <br className="visible-xs" />
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default withTranslation('single-booking')(SingleBookingHeader);
