import React, { ChangeEvent, FC, MouseEvent, useState } from 'react';
import Formsy from 'formsy-react';
import { useRouter } from 'next/router';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import validators from '../../helpers/validators';
import { TRACKER_CATEGORY_LOGIN } from '../../helpers/trackerConstants';
import { SERVER_ERROR_CODE } from '../../helpers/constants';
import appConfig from '../../helpers/config';
import { LEGACY_APPLICATION } from '../../stores/application';

import FormField from '../../components/FormField';
import SubmitButton from './LoginSubmitButton';

import './login-section.scss';

const {
  publicRuntimeConfig: { HTTP_HOST },
} = appConfig;

const LoginSection: FC<WithTranslationType> = ({ t }: WithTranslationType) => {
  const { config, i18nUtils, session, tracker } = useStore();
  const [form, setState] = useState({
    email: '',
    password: '',
    isLoading: false,
    isSubmitted: false,
  });
  const [errors, setErrors] = useState({});
  const router = useRouter();

  const validations = validators(config, i18nUtils.umRegion);
  const passwordMinLength = config.getValue('locale.password-min-length', i18nUtils.umRegion);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event) {
      setState({
        ...form,
        [event.target.name]: event.target.value,
      });

      setErrors({});
    }
  };

  const onClickApply = (event: MouseEvent<HTMLSpanElement>): void => {
    event.preventDefault();

    tracker.track('Clicked on apply now as partner therapist', {
      category: TRACKER_CATEGORY_LOGIN,
    });

    router.push(`/${i18nUtils.locale}/signup`);
  };

  const onClickForgotPassword = (event: MouseEvent<HTMLSpanElement>): void => {
    event.preventDefault();

    tracker.track('Clicked on forgot password', {
      category: TRACKER_CATEGORY_LOGIN,
    });

    router.push(`/${i18nUtils.locale}/forgot-password`);
  };

  const validate = (): boolean => {
    setState({
      ...form,
      isSubmitted: true,
    });

    return true;
  };

  const onValid = (): void => {
    if (form.isSubmitted) {
      validate();
    }
  };

  const setError = (name: string, value: string): void => {
    setErrors({
      ...errors,
      [name]: value,
    });
  };

  const onValidSubmit = (): void => {
    setState({
      ...form,
      isLoading: true,
    });

    if (validate()) {
      session
        .signin(form)
        .then(data => {
          setState({
            ...form,
            isLoading: false,
          });

          if (data.status === 'ERROR') {
            setError(SERVER_ERROR_CODE, data.code);

            tracker.track('Login error', {
              category: TRACKER_CATEGORY_LOGIN,
              message: data && data.message,
            });
          } else {
            tracker.track('Logged in successfuly!', {
              category: TRACKER_CATEGORY_LOGIN,
              data,
            });

            session.login(data);

            const { worker } = data;
            const { application } = worker;

            const {
              DECLINED,
              IN_REVIEW,
              INTERVIEW,
              ON_BOARDING,
              PENDING,
              SUBMITTED,
              SUCCESSFUL,
            } = LEGACY_APPLICATION;

            switch (true) {
              case worker.operatorPartner_id && !worker.verifiedEmail:
                router.push(`/${i18nUtils.locale}/sign-agreement`);
                break;
              case worker.active ||
                (application &&
                  application.status === SUCCESSFUL &&
                  worker.signedLatestAgreement &&
                  worker.verifiedEmail):
                router.push(`/${i18nUtils.locale}/bookings`);
                break;
              case application && application.status === DECLINED:
                router.push(`/${i18nUtils.locale}/application-declined`);
                break;
              case application && application.status === PENDING:
                router.push(`/${i18nUtils.locale}/submit-application`);
                break;
              case application.status !== SUCCESSFUL &&
                application.status !== DECLINED &&
                (application.status === SUBMITTED ||
                  application.status === IN_REVIEW ||
                  !worker.residentialAddress ||
                  !worker.name ||
                  !worker.mobile ||
                  !worker.residentialAddress.address1 ||
                  application.status === INTERVIEW ||
                  application.status === ON_BOARDING):
                window.location.href = `https://${HTTP_HOST}/almost-there`;
                break;
              case !worker.signedLatestAgreement || !worker.verifiedEmail:
                router.push(`/${i18nUtils.locale}/sign-agreement`);
                break;
              default:
                router.push(`/${i18nUtils.locale}/bookings`);
                break;
            }
          }
        })
        .catch(error => {
          tracker.track('Error occurred while trying to login', {
            category: TRACKER_CATEGORY_LOGIN,
            error,
          });

          setState({
            ...form,
            isLoading: false,
          });
        });
    }
  };

  return (
    <div className="columns is-mobile login-section__container">
      <Formsy
        className="column login-section__form is-offset-1-mobile is-10-mobile is-offset-3-tablet is-6-tablet is-offset-4-desktop is-4-desktop"
        onValid={onValid}
        onValidSubmit={onValidSubmit}
        preventExternalInvalidation
      >
        <div className="text-center text-left-xs">
          <h1 className="login-section__title">{t('login_title')}</h1>
          <p className="login-section__secondary-text">
            <span>{t('login_subtitle-no-account')}</span>
            <button className="anchor login-section__link" onClick={onClickApply} type="button">
              {t('login_subtitle-apply')}
            </button>
          </p>
        </div>
        <FormField
          id="js-email"
          labelText={t('login_email-label')}
          name="email"
          onChange={onChange}
          required
          t={t}
          type="email"
          validationError={t('login_invalid-email-error-message')}
          validationErrors={{
            isDefaultRequiredValue: t('login_required-email-field-error-message'),
          }}
          validations={{
            customIsEmail: validations.isEmail,
          }}
          value={form.email}
        />
        <FormField
          id="js-password"
          labelText={t('login_password-label')}
          name="password"
          onChange={onChange}
          required
          showPasswordButton
          t={t}
          type="password"
          validationErrors={{
            isDefaultRequiredValue: t('login_required-password-field-error-message'),
            isPassword: t('login_invalid-password-error-message', {
              passwordMinLength,
            }),
          }}
          validations={{
            isPassword: validations.isPassword,
          }}
          value={form.password}
        />
        <SubmitButton errors={errors} loading={false} t={t} />
        <p className="text-center">
          <button
            className="anchor login-section__link"
            onClick={onClickForgotPassword}
            type="button"
          >
            {t('login_forgot-password')}
          </button>
        </p>
      </Formsy>
    </div>
  );
};

export default withTranslation('login')(LoginSection);
