export default (config: any, umRegion: string) => {
  // TODO: move it to config
  const referralCodeRegex = /^[A-Z0-9]{0,14}$/;
  const emailRegex = config.getValue('locale.email-validation-regex', umRegion);
  const phoneRegex = config.getValue('locale.phone-validation-regex', umRegion);
  const passwordMinLength = config.getValue('locale.password-min-length', umRegion);

  const isEmail = (_: unknown, value: string): boolean => {
    return new RegExp(emailRegex).test(value);
  };

  const isPassword = (_: unknown, value: string): boolean => {
    return typeof value === 'string' && value.length >= passwordMinLength;
  };

  const isPhone = (_: unknown, value: string): boolean => {
    return new RegExp(phoneRegex).test(value);
  };

  const isReferralCode = (_: unknown, value: string): boolean => {
    return typeof value === 'undefined' || referralCodeRegex.test(value);
  };

  return {
    isEmail,
    isPassword,
    isPhone,
    isReferralCode,
  };
};
