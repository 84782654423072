import React from 'react';

import './spinner.scss';

import spinner from '../../public/static/images/spinner-light.svg';

function Spinner() {
  return (
    <div className="spinner-container">
      <img alt="loading" src={spinner} />
    </div>
  );
}

export default Spinner;
