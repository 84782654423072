import React, { Fragment, KeyboardEvent } from 'react';
import Formsy from 'formsy-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import { action, IReactionDisposer, observable, reaction } from 'mobx';
import { Element, scroller } from 'react-scroll';
import autobind from 'autobind-decorator';

import { withTranslation, WithTranslationType } from '../../server/i18n';
import { IApplicationFormErrors, IApplicationFormData } from '../../../types/signup';
import { GenderEnum, TransportTypeEnum, TreatmentToBothGendersEnum } from '../../helpers/enums';
import appConfig from '../../helpers/config';

import ApplicationSubmitButton from './ApplicationSubmitButton';
import PreferredHours from '../signup/SignupPreferredHours';
import ProvideTreatmentTo from '../signup/SignupProvideTreatmentTo';
import QualifiedIn from '../signup/SignupQualifiedIn';
import SelectGender from '../signup/SignupSelectGender';

import TransportType from '../signup/SignupSelectTransportType';

/**
 * This can be renamed and moved once the application split has taken place as all the sub components use the same style file
 * for some unknown reason - looks like a rushed copy paste job from the old site
 */
import '../signup/signup-section.scss';

import {
  GENDER_ERROR,
  PREFERRED_HOURS_ERROR,
  SCROLL_DELAY,
  SCROLL_DURATION,
  SCROLL_OFFSET,
  SELECTED_TREATMENTS_ERROR,
  SERVER_ERROR_CODE,
  SERVER_VALIDATION_ERROR,
  TRANSPORT_TYPE_ERROR,
  TREATMENTS_TO_BOTH_GENDERS_ERROR,
} from '../../helpers/constants';

import { TRACKER_CATEGORY_CREATE_ACCOUNT } from '../../helpers/trackerConstants';
import { withRedirectToBookings } from '../../hocs/withRedirectToBookings';
import LoadingIndicator from '../single-booking/LoadingIndicator';

const {
  publicRuntimeConfig: { HTTP_HOST },
} = appConfig;

const ENTER_KEY_CODE = 13;

interface Props extends WithTranslationType {
  application: {
    submitApplication: (
      data: IApplicationFormData,
    ) => Promise<{
      code?: string;
      message?: string;
      status: string;
    }>;
  };
  config: {
    getValue: Function;
  };
  i18nUtils: {
    locale: string;
    umRegion: string;
  };
  router: {
    asPath: string;
  };
  session: {
    applicationStatus?: string;
    city: {
      id: string;
    };
    isInitialising: boolean;
    isLoggedIn: boolean;
  };
  tracker: {
    track: Function;
  };
}

@(withRouter as any)
@inject(({ store: { application, config, i18nUtils, selection, session, tracker } }) => ({
  application,
  config,
  i18nUtils,
  selection,
  session,
  tracker,
}))
@observer
export class SubmitApplicationForm extends React.Component<Props, null> {
  qualifiedFaqArticle: string;
  listeners: IReactionDisposer[];

  @observable errors: IApplicationFormErrors;
  @observable formData: IApplicationFormData;
  @observable loading = false;
  @observable submitted = false;
  @observable showVerticalsSignup: boolean;

  constructor(props: Props) {
    super(props);
    const { config, i18nUtils } = props;

    this.formData = {
      gender: undefined,
      preferredHours: {
        afternoons: false,
        evenings: false,
        mornings: false,
        weekdays: false,
        weekends: false,
      },
      selectedTreatments: {
        beauty: observable.array([]),
        fitness: observable.array([]),
        lifestyle: observable.array([]),
        massage: observable.array([]),
        medical: observable.array([]),
      },
      transportType: undefined,
      treatmentToBothGenders: undefined,
      verticals: {
        beauty: false,
        fitness: false,
        lifestyle: false,
        massage: false,
        medical: false,
      },
    };

    this.errors = {
      gender: false,
      preferredHours: false,
      selectedTreatments: false,
      serverValidation: false,
      serverErrorCode: undefined,
      transportType: false,
      treatmentToBothGenders: false,
    };

    this.qualifiedFaqArticle = config.getValue(
      'hero.portal.signup.qualified-faq-article-stub',
      i18nUtils.umRegion,
    );
  }

  componentDidMount(): void {
    // reactions wont happen with destructured assignments
    /* eslint-disable react/destructuring-assignment */
    this.listeners = [
      reaction(
        () => this.props.session.applicationStatus,
        applicationStatus => {
          const { session } = this.props;
          // NOTE this will be temporary - basic check for now - will remove all of this with the fountain integration
          if (!session.isInitialising && applicationStatus !== 'pending') {
            window.location.href = `https://${HTTP_HOST}/signup`;
          }
        },
        {
          fireImmediately: true,
          name: 'check for non auth entry',
        },
      ),
      reaction(
        () => this.formData.gender,
        gender => {
          const { tracker } = this.props;

          tracker.track('Gender for therapist selected', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            gender,
          });
        },
        {
          name: 'Track gender selected changes',
        },
      ),
      reaction(
        () => [
          this.formData.preferredHours.afternoons,
          this.formData.preferredHours.evenings,
          this.formData.preferredHours.mornings,
          this.formData.preferredHours.weekdays,
          this.formData.preferredHours.weekends,
        ],
        preferredHours => {
          this.props.tracker.track('Preferred hours for therapist changed', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            preferredHours,
          });
        },
        {
          name: 'Track preferred hours changes',
        },
      ),
      reaction(
        () => ({
          beauty: this.formData.selectedTreatments.beauty.toJS(),
          fitness: this.formData.selectedTreatments.fitness.toJS(),
          lifestyle: this.formData.selectedTreatments.lifestyle.toJS(),
          massage: this.formData.selectedTreatments.massage.toJS(),
          medical: this.formData.selectedTreatments.medical.toJS(),
        }),
        selectedTreatments => {
          const { tracker } = this.props;

          tracker.track('Updated Treatments List', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            selectedTreatments,
          });
        },
        {
          name: 'Track treatment list changes',
        },
      ),
      reaction(
        () => this.formData.transportType,
        transportType => {
          const { tracker } = this.props;

          tracker.track('Updated transport type', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            transportType,
          });
        },
        {
          name: 'Track transport type changes',
        },
      ),
      reaction(
        () => this.formData.treatmentToBothGenders,
        treatmentToBothGenders => {
          const { tracker } = this.props;

          tracker.track('Updated Happy to massage both men and women', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            treatmentToBothGenders,
          });
        },
        {
          name: 'Track gender treatment preferences',
        },
      ),
      reaction(
        () => ({
          beauty: this.formData.verticals.beauty,
          fitness: this.formData.verticals.fitness,
          lifestyle: this.formData.verticals.lifestyle,
          massage: this.formData.verticals.massage,
          medical: this.formData.verticals.medical,
        }),
        verticals => {
          const { tracker } = this.props;

          tracker.track('Verticals changed', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            verticals,
          });
        },
        {
          name: 'track to vertical changes',
        },
      ),
      reaction(
        () => this.props.session && this.props.session.city && this.props.session.city.id,
        city => {
          const { config, i18nUtils } = this.props;

          const verticalsCitiesList = config.getValue(
            'hero.portal.signup.verticals-cities-list',
            i18nUtils.umRegion,
          );

          this.showVerticalsSignup = verticalsCitiesList.indexOf(city) > -1;
        },
        {
          fireImmediately: true,
          name: 'Check vertical config for cities',
        },
      ),
    ];
    /* eslint-enable react/destructuring-assignment */
  }

  componentWillUnmount(): void {
    while (this.listeners.length) {
      this.listeners.pop()();
    }
  }

  // client side validation
  @autobind
  @action('onInvalidSubmit - SubmitApplicationForm')
  onInvalidSubmit(): void {
    this.validateForm();
  }

  /* eslint-disable class-methods-use-this */
  @autobind
  onKeyPress(event: KeyboardEvent): void {
    if (event.which === ENTER_KEY_CODE) {
      event.preventDefault();
    }
  }
  /* eslint-enable class-methods-use-this */

  @autobind
  onValid(): void {
    if (this.submitted) {
      this.validateForm();
    }
  }

  // server side validation
  @autobind
  @action('onValidSubmit - SubmitApplicationForm')
  onValidSubmit(): void {
    this.loading = true;
    if (this.validateForm()) {
      const { application, tracker } = this.props;

      application
        .submitApplication(this.formData)
        .then(data => {
          this.loading = false;
          if (data.status === 'ERROR') {
            this.setError(SERVER_VALIDATION_ERROR, true);
            this.setError(SERVER_ERROR_CODE, data.code);

            tracker.track('Submit application error', {
              category: TRACKER_CATEGORY_CREATE_ACCOUNT,
              message: data && data.message,
            });
          } else {
            const {
              session: { city },
            } = this.props;

            let almostThereLink = `https://${HTTP_HOST}/almost-there`;
            if (city && city.id) {
              almostThereLink += `?city=${city.id}`;
            }

            // TODO: enable line below when /almost-there page will be in place
            // Router.push('/almost-there');
            window.location.href = almostThereLink;
          }
        })
        .catch(error => {
          tracker.track('Error occurred while submitting application form', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            error,
          });

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  @autobind
  @action('setError - SubmitApplicationForm')
  setError(key: string, value: boolean | string): void {
    this.errors[key] = value;
  }

  @autobind
  @action('setGender - SubmitApplicationForm')
  setGender(gender: GenderEnum): void {
    this.formData.gender = gender;
    this.setError(GENDER_ERROR, false);
  }

  @autobind
  @action('setPreferredHours - SubmitApplicationForm')
  setPreferredHours(event: React.ChangeEvent<HTMLInputElement>): void {
    const { preferredHours } = this.formData;
    const { checked, name } = event.target;

    preferredHours[name] = checked;

    const hasError = Object.keys(preferredHours).every(key => preferredHours[key] === false);
    this.setError(PREFERRED_HOURS_ERROR, hasError);
  }

  @autobind
  @action('setSpeciality - SubmitApplicationForm')
  setSpeciality(vertical: string) {
    return (event: React.ChangeEvent<HTMLInputElement>): void => {
      const { name } = event.target;
      const { selectedTreatments, verticals } = this.formData;
      const index = selectedTreatments[vertical].indexOf(name);

      if (index > -1) {
        selectedTreatments[vertical].splice(index, 1);
      } else {
        selectedTreatments[vertical] = [...selectedTreatments[vertical], name];
        verticals[vertical] = true;
        this.setError(SELECTED_TREATMENTS_ERROR, false);
      }
    };
  }

  @autobind
  @action('setTransportType - SubmitApplicationForm')
  setTransportType(transportType: TransportTypeEnum): void {
    this.formData.transportType = transportType;
    this.setError(TRANSPORT_TYPE_ERROR, false);
  }

  @autobind
  @action('setTreatmentToBothGenders - SubmitApplicationForm')
  setTreatmentToBothGenders(answer: TreatmentToBothGendersEnum): void {
    this.formData.treatmentToBothGenders = answer;
    this.setError(TREATMENTS_TO_BOTH_GENDERS_ERROR, answer === TreatmentToBothGendersEnum.NO);
  }

  @autobind
  @action('setVertical - SubmitApplicationForm')
  setVertical(event): void {
    const { name, checked } = event.target;
    this.formData.verticals[name] = checked;

    if (!checked) {
      this.formData.selectedTreatments[name] = [];
    }
  }

  /* eslint-disable class-methods-use-this */
  @action('scrollToElement - SubmitApplicationForm')
  scrollToElement(name: string): void {
    scroller.scrollTo(name, {
      duration: SCROLL_DURATION,
      delay: SCROLL_DELAY,
      offset: SCROLL_OFFSET,
      smooth: true,
    });
  }
  /* eslint-enable class-methods-use-this */

  @action('validateForm - SubmitApplicationForm')
  validateForm(): boolean {
    const { scrollToElement, setError } = this;
    const {
      gender,
      preferredHours,
      selectedTreatments,
      transportType,
      treatmentToBothGenders,
    } = this.formData;

    this.submitted = true;

    const selectedTreatmentsArray = Object.values(selectedTreatments).reduce(
      (previous, current) => {
        return previous.concat(current.toJS());
      },
      [],
    );

    const preferredHoursInvalid = Object.keys(preferredHours).every(
      key => preferredHours[key] === false,
    );

    const errors = new Map<string, boolean>([
      [SELECTED_TREATMENTS_ERROR, selectedTreatmentsArray.length === 0],
      [GENDER_ERROR, !gender],
      [
        TREATMENTS_TO_BOTH_GENDERS_ERROR,
        !treatmentToBothGenders || treatmentToBothGenders === 'no',
      ],
      [PREFERRED_HOURS_ERROR, preferredHoursInvalid],
      [TRANSPORT_TYPE_ERROR, !transportType],
    ]);

    errors.forEach((value, key) => setError(key, value));

    /* eslint-disable no-restricted-syntax*/
    for (const [key, value] of errors) {
      if (value) {
        scrollToElement(key);
        return false;
      }
    }
    /* eslint-enable no-restricted-syntax*/

    return true;
  }

  render(): JSX.Element {
    const {
      errors,
      formData,
      loading,
      onInvalidSubmit,
      onKeyPress,
      onValid,
      onValidSubmit,
      setGender,
      setPreferredHours,
      setSpeciality,
      setTransportType,
      setTreatmentToBothGenders,
      setVertical,
      showVerticalsSignup,
    } = this;
    const { gender, preferredHours, transportType, treatmentToBothGenders } = formData;
    const {
      config,
      i18nUtils,
      session: { city, isInitialising, isLoggedIn },
    } = this.props;

    if (!city && isInitialising) {
      return <LoadingIndicator />;
    }

    if (isLoggedIn && !city) {
      return <div>Something is wrong</div>;
    }

    return (
      <Formsy
        onInvalidSubmit={onInvalidSubmit}
        onKeyPress={onKeyPress}
        onValid={onValid}
        onValidSubmit={onValidSubmit}
        preventExternalInvalidation
      >
        <Fragment>
          <Fragment>
            <Element name={SELECTED_TREATMENTS_ERROR}>
              {city && city.id && (
                <QualifiedIn
                  city={city.id}
                  config={config}
                  errors={errors}
                  formData={formData}
                  i18nUtils={i18nUtils}
                  onChangeSpeciality={setSpeciality}
                  onChangeVertical={setVertical}
                  scrollTo={this.scrollToElement}
                  showVerticalsSignup={showVerticalsSignup}
                />
              )}
            </Element>
            <Element name={GENDER_ERROR}>
              <SelectGender errors={errors} gender={gender} setGender={setGender} />
            </Element>
            <Element name={TREATMENTS_TO_BOTH_GENDERS_ERROR}>
              <ProvideTreatmentTo
                errors={errors}
                setTreatmentToBothGenders={setTreatmentToBothGenders}
                treatmentToBothGenders={treatmentToBothGenders}
              />
            </Element>
            <Element name={PREFERRED_HOURS_ERROR}>
              <PreferredHours
                errors={errors}
                setPreferredHours={setPreferredHours}
                values={preferredHours}
              />
            </Element>
            <Element name={TRANSPORT_TYPE_ERROR}>
              <TransportType
                errors={errors}
                setTransportType={setTransportType}
                transportType={transportType}
              />
            </Element>
            <ApplicationSubmitButton
              errors={errors}
              loading={loading}
              scrollTo={this.scrollToElement}
            />
          </Fragment>
        </Fragment>
      </Formsy>
    );
  }
}

export default withRedirectToBookings(withTranslation('signup')(SubmitApplicationForm));
