import React, { ChangeEvent, Fragment } from 'react';
import { observer } from 'mobx-react';

import Checkbox from '../../components/Checkbox';
import { WithTranslationType, withTranslation } from '../../server/i18n';

interface CheckboxProps {
  title: string;
}

interface Props extends WithTranslationType {
  errors: {
    preferredHours: boolean;
  };
  setPreferredHours: (event: ChangeEvent) => void;
  values: {
    weekdays: boolean;
    weekends: boolean;
    mornings: boolean;
    afternoons: boolean;
    evenings: boolean;
  };
}

const PreferredHours = ({ errors, setPreferredHours, t, values }: Props) => {
  const preferredHours = new Map<string, CheckboxProps>([
    ['weekdays', { title: t('signup_preferred-hours-weekdays') }],
    ['weekends', { title: t('signup_preferred-hours-weekends') }],
    ['mornings', { title: t('signup_preferred-hours-mornings') }],
    ['afternoons', { title: t('signup_preferred-hours-afternoons') }],
    ['evenings', { title: t('signup_preferred-hours-evenings') }],
  ]);

  return (
    <Fragment>
      <p>{t('signup_preferred-hours-question-label')}</p>
      {[...preferredHours.entries()].map(iterator => (
        <Checkbox
          checked={values[iterator[0]]}
          id={`js-preferred-hours-${iterator[0]}`}
          key={iterator[0]}
          name={iterator[0]}
          onChange={setPreferredHours}
        >
          {iterator[1].title}
        </Checkbox>
      ))}
      {errors.preferredHours && (
        <div
          className="signup__error"
          data-qa="preferred-hours-error"
          id="js-preferred-hours-error"
        >
          <span>{t('signup_preferred-hours-required-error')}</span>
        </div>
      )}
      <hr />
    </Fragment>
  );
};

export default withTranslation('signup')(observer(PreferredHours));
