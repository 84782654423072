import React, { FC, ReactNode } from 'react';
import cn from 'classnames';

import './error-message.scss';

interface Props {
  children: ReactNode;
  className?: string;
}

const ErrorMessage: FC<Props> = ({ children, className }: Props) => {
  return <div className={cn('error', className)}>{children}</div>;
};

export default ErrorMessage;
