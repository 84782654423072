/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { FC } from 'react';

import { observer } from 'mobx-react';
import config from '../../helpers/config';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { TRACKER_CATEGORY_SINGLE_BOOKING_DETAILS } from '../../helpers/trackerConstants';
import BookingNoteForm from './BookingNoteForm';
import SingleBookingRatingAndComments from './SingleBookingRatingAndComments';
import SingleBookingItems from './SingleBookingItems';
import LoadingIndicator from './LoadingIndicator';
import { useStore } from '../../contexts/storeContext';

const {
  publicRuntimeConfig: { GOOGLE_MAPS_CLIENT_ID },
} = config;

const SingleBookingMapSection: FC<WithTranslationType> = observer(({ t }) => {
  const {
    singleBooking: { booking, confirmBooking, isConfirming, isPastBooking },
    tracker,
  } = useStore();

  const LoadingElement = (): JSX.Element => {
    return <div style={{ height: '100%' }} />;
  };

  const ContainerElement = (): JSX.Element => {
    return <div style={{ height: '400px' }} />;
  };

  const handleConfirm = (): void => {
    tracker.track('Pressed Confirm Booking Button', {
      category: TRACKER_CATEGORY_SINGLE_BOOKING_DETAILS,
      booking: booking.id,
    });

    confirmBooking();
  };

  return (
    <div className="column is-12-mobile is-5-tablet is-offset-1-tablet single-booking__map-section">
      {!booking.isConfirmed && (
        <>
          <div className="columns">
            <div className="column is-12">
              <button
                className="button button--action-btn button--capitalize single-booking__confirm-button hidden-xs"
                onClick={handleConfirm}
                type="button"
              >
                {t('single-booking_confirm-button')}
              </button>
              {isConfirming && <LoadingIndicator />}
            </div>
          </div>
        </>
      )}
      <br />
      <SingleBookingItems />
      <SingleBookingRatingAndComments />
      <BookingNoteForm />
    </div>
  );
});

export default withTranslation('single-booking')(SingleBookingMapSection);
/* eslint-enable @typescript-eslint/no-unused-vars */
