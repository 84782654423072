import React from 'react';
import { computed, observable, reaction, IReactionDisposer } from 'mobx';
import { observer, inject } from 'mobx-react';

import LazyLoad, { forceCheck } from 'react-lazyload';

const HIGH_RES_SCREEN_MEDIA_QUERY =
  'only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx)';

// Props info:
//
// disableWebp - disable use of webp images
// highResImg - normal image URL
// highResImg2x - @2x image URL
// lowResImg - low quality image url used for pre-render
//
// mediaQueries - array of media queries, ordered by descending value of width.
// It should be in the following format, for example:
// [{
//   query: '(max-width: 600px)',
//   highResImg: 'https://res.cloudinary.com/huxr6hrje/image/upload/w_600/v1527067430/b2c-art/landing-pages/1x/Desktop-Hero_1x-min-min-min.jpg',
//   highResImg2x: 'https://res.cloudinary.com/huxr6hrje/image/upload/w_1200/v1526982871/b2c-art/landing-pages/Desktop-Hero_2x-min-min.jpg',
// }]
interface ProgressiveImageProps {
  altText: string;
  className?: string;
  customClass?: string;
  disableWebp?: boolean;
  highResImg: string;
  highResImg2x: string;
  initialLoadComplete?: boolean;
  lowResImg: string;
  mediaQueries?: object[];
  screen?: {
    supportsWebp: boolean;
    width: number;
  };
  store?: {
    setInitialLoadComplete: Function;
  };
}

@inject(({ store, store: { initialLoadComplete, screen } }) => ({
  initialLoadComplete,
  screen,
  store,
}))
@observer
class ProgressiveImage extends React.Component<ProgressiveImageProps, any> {
  listeners: IReactionDisposer[];
  lastWidth: number;

  @observable showHighRes = false;
  @observable supportsWebp = false;
  @observable selectedHighRes;
  @observable selectedHighRes2x;

  constructor(props: ProgressiveImageProps) {
    super(props);
    const { initialLoadComplete, highResImg, highResImg2x, store } = this.props;
    this.selectedHighRes = highResImg;
    this.selectedHighRes2x = highResImg2x;

    if (
      typeof document !== 'undefined' &&
      document.readyState === 'complete' &&
      !initialLoadComplete
    ) {
      store.setInitialLoadComplete(true);
    }
  }

  componentDidMount(): void {
    const { screen } = this.props;
    this.listeners = [
      reaction(
        () => this.props.initialLoadComplete,
        initialLoadComplete => {
          if (initialLoadComplete) {
            this.showHighRes = true;
            forceCheck();
          }
        },
        {
          fireImmediately: true,
          name: 'Check if content has finished loading',
        },
      ),
      reaction(
        () => screen.width,
        width => {
          if (width !== this.lastWidth) {
            this.lastWidth = width;

            this.getImage(width);
          }
        },
        {
          fireImmediately: true,
          name: 'Get image for new screen width',
        },
      ),
      reaction(
        () => [this.props.highResImg, this.props.highResImg2x, this.props.lowResImg],
        _ => {
          this.getImage(0);
        },
        {
          fireImmediately: true,
          name: 'Get image after image url changed',
        },
      ),
      reaction(
        () => screen.supportsWebp,
        supportsWebp => {
          if (supportsWebp) {
            this.getImage(this.props.screen.width);
          }
        },
        {
          fireImmediately: true,
          name: 'Get image in webp',
        },
      ),
      reaction(
        () => screen.width,
        width => {
          if (width !== this.lastWidth) {
            this.lastWidth = width;

            this.getImage(width);
          }
        },
        {
          fireImmediately: true,
          name: 'Get image for new screen width',
        },
      ),
    ];
  }

  componentWillUnmount(): void {
    while (this.listeners.length) {
      this.listeners.pop()();
    }
  }

  // Get image url in webp format
  getWebpImage = (url: string): string => {
    const imageFilename = url.substring(url.lastIndexOf('/') + 1);

    const imageExtension = imageFilename.substring(imageFilename.lastIndexOf('.') + 1);

    return url.replace(imageExtension, 'webp');
  };

  // Set the correct image depending on the current state
  getImage = (width: number): void => {
    const { disableWebp, highResImg, highResImg2x, mediaQueries, screen } = this.props;

    let tempHighRes = highResImg;
    let tempHighRes2x = highResImg2x;

    if (mediaQueries) {
      if (typeof window === 'undefined') return null;

      mediaQueries.forEach((mediaQuery: any) => {
        if (window.matchMedia(mediaQuery.query).matches) {
          tempHighRes = mediaQuery.highResImg;
          tempHighRes2x = mediaQuery.highResImg2x;
        }
      });
    }

    if (screen.supportsWebp && !disableWebp) {
      this.selectedHighRes = this.getWebpImage(tempHighRes);
      this.selectedHighRes2x = tempHighRes2x && this.getWebpImage(tempHighRes2x);
    } else {
      this.selectedHighRes = tempHighRes;
      this.selectedHighRes2x = tempHighRes2x;
    }
  };

  @computed get imageUrl() {
    const { lowResImg } = this.props;
    const { selectedHighRes, selectedHighRes2x, showHighRes, use2xImage } = this;

    if (!showHighRes) return lowResImg;

    if (!selectedHighRes) return null;

    return use2xImage && selectedHighRes2x ? selectedHighRes2x : selectedHighRes;
  }

  @computed get use2xImage(): boolean {
    if (typeof window === 'undefined') return null;

    const { showHighRes } = this;

    return showHighRes && window.matchMedia(HIGH_RES_SCREEN_MEDIA_QUERY).matches;
  }

  render(): JSX.Element {
    const {
      altText,
      customClass,
      disableWebp,
      initialLoadComplete,
      highResImg,
      highResImg2x,
      lowResImg,
      mediaQueries,
      screen,
      ...rest
    } = this.props;

    const { imageUrl } = this;
    const placeholder = <span />;

    return (
      <LazyLoad offset={100} placeholder={placeholder}>
        <img alt={altText} className={customClass} src={imageUrl} {...rest} />
      </LazyLoad>
    );
  }
}

export default ProgressiveImage;
