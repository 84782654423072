import React from 'react';
import { inject, observer } from 'mobx-react';

interface Props {
  article?: {
    content: string;
    getArticle: Function;
    title: string;
  };
  articleId: string;
  config?: {
    getValue: Function;
  };
  i18nUtils?: {
    umRegion: string;
  };
}

@inject(({ store: { article } }) => ({
  article,
}))
@observer
class FAQPanel extends React.Component<Props, null> {
  componentDidMount() {
    const { article, articleId } = this.props;

    if (articleId) {
      article.getArticle(articleId);
    }
  }

  render() {
    const { article } = this.props;
    const { content, title } = article;

    return (
      <div className="faq-panel">
        <div className="faq-panel__article">
          <h2 className="faq-panel__article-title">{title}</h2>
          <div
            className="faq-panel__article-content"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    );
  }
}

export default FAQPanel;
