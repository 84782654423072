import React, { FC } from 'react';
import cn from 'classnames';

import { withTranslation } from '../../server/i18n';

import BookingItemType from './BookingItemType';
import BookingTotal from './BookingTotal';
import BookingChangeFees from './BookingChangeFees';
import { currency } from '../../helpers';
import { useStore } from '../../contexts/storeContext';

const SingleBookingItems: FC = () => {
  const {
    session,
    singleBooking: { booking, isPastBooking },
  } = useStore();

  return (
    <div
      className={cn(
        'single-booking__booking-items columns is-multiline',
        isPastBooking && 'single-booking__booking-items--is-past',
      )}
    >
      {booking.items &&
        booking.items.map(item => {
          return (
            <div className="single-booking__booking-item" key={item.id}>
              <div className="columns is-multiline is-mobile">
                <div className="column is-12-mobile is-4-tablet text-left">
                  <BookingItemType item={item} />
                </div>
                <div className="column is-7-mobile is-5-tablet text-left">{item.durationName}</div>
                {!session.hideEarnings ? (
                  <div className="column is-5-mobile is-3-tablet text-right single-booking__booking-item-price">
                    {currency((item.total - item.fee).toFixed(2), booking.currencySymbol)}
                  </div>
                ) : null}
              </div>
              <hr />
            </div>
          );
        })}
      {booking.changeFees > 0 && (
        <BookingChangeFees changeFees={currency(booking.changeFees, booking.currencySymbol)} />
      )}
      {!session.hideEarnings ? (
        <BookingTotal bookingTotal={currency(booking.bookingTotal, booking.currencySymbol)} />
      ) : null}
    </div>
  );
};

export default withTranslation('single-booking')(SingleBookingItems);
