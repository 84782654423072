import React from 'react';
import { action, IReactionDisposer, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';
import autobind from 'autobind-decorator';

import { parseCookies, setCookie } from 'nookies';
import { Trans, withTranslation, WithTranslationType } from '../server/i18n';
import { browserOnly } from './EnvSplit';

import Link from './Link';

import './cookie-banner.scss';

const COOKIE_SCROLL_OFFSET = 300;

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    attachEvent: any;
    detachEvent: any;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

interface CookieBannerProps extends WithTranslationType {
  cookieName: string;
  store?: any;
  tracker?: {
    track: Function;
  };
}

@browserOnly
@inject(({ store, store: { tracker } }) => ({
  store,
  tracker,
}))
@observer
class CookieBanner extends React.Component<CookieBannerProps> {
  cookies: { [key: string]: string };
  listeners: IReactionDisposer[];
  supportsPageOffset: boolean;

  @observable initialPosition: number;
  @observable isDismissed = false;

  componentDidMount(): void {
    const { cookieName, store } = this.props;

    // Parse the cookies
    this.cookies = parseCookies(store);

    this.isDismissed = !!this.cookies[cookieName];

    this.supportsPageOffset = window.pageYOffset !== undefined;

    if (!this.isDismissed) {
      // Add the scroll event listener
      window.addEventListener('load', () => {
        this.initialPosition = this.supportsPageOffset
          ? window.pageYOffset
          : document.documentElement.scrollTop || document.body.scrollTop;

        if (window.addEventListener) {
          window.addEventListener('scroll', this.onScroll);
        } else if (window.attachEvent) {
          window.attachEvent('scroll', this.onScroll);
        }
      });
    }

    this.listeners = [
      reaction(
        () => this.isDismissed,
        isDismissed => {
          if (isDismissed) {
            // remove our scroll listener as component doesn't unmount on removal
            if (window.removeEventListener) {
              window.removeEventListener('scroll', this.onScroll);
            } else if (window.detachEvent) {
              window.detachEvent('scroll', this.onScroll);
            }
          }
        },
        {
          name: 'Remove scroll listener - Cookie Banner',
        },
      ),
    ];
  }

  componentWillUnmount(): void {
    // remove our scroll listener
    if (window.removeEventListener) {
      window.removeEventListener('scroll', this.onScroll);
    } else if (window.detachEvent) {
      window.detachEvent('scroll', this.onScroll);
    }

    while (this.listeners.length) {
      this.listeners.pop()();
    }
  }

  @autobind
  @action('onScroll - CookieBanner')
  onScroll(): void {
    const currentPos = this.supportsPageOffset
      ? window.pageYOffset
      : document.documentElement.scrollTop || document.body.scrollTop;
    // Remove our cookie banner if the user has scrolled far enough on the site
    if (
      this.initialPosition !== undefined &&
      (currentPos <= this.initialPosition - COOKIE_SCROLL_OFFSET ||
        currentPos >= this.initialPosition + COOKIE_SCROLL_OFFSET)
    ) {
      this.dismissCookie('Scroll');
    }
  }

  @autobind
  @action('onClickDismiss - CookieBanner')
  onClickDismiss(event): void {
    event.preventDefault();

    this.dismissCookie('Button');
  }

  @autobind
  @action('dismissCookie - CookieBanner')
  dismissCookie(dismissType): void {
    if (!this.isDismissed) {
      const { cookieName, store, tracker } = this.props;

      tracker.track(`Cookie Banner Dismissed ${dismissType}`, {
        category: 'Cookie Banner',
      });

      setCookie(store, cookieName, 'true', {
        path: '/',
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      });

      this.isDismissed = true;
    }
  }

  render(): JSX.Element {
    const { isDismissed, onClickDismiss } = this;
    const { t } = this.props;

    if (!isDismissed) {
      return (
        <div className="cookie-banner__container">
          <div className="cookie-banner">
            {/* <a
              className="cookie-banner__dismiss"
              href="#dismiss-banner"
              onClick={onClickDismiss}
              title="Dismiss"
            /> */}
            <div className="cookie-banner__content">
              <div className="cookie-banner__text">
                <p className="cookie-banner__text-paragraph">{t('cookie-banner_text-line-one')}</p>
                <Trans i18nKey="cookie-banner_text-line-two">
                  Please see our Cookies policy available
                  <Link route="/cookies"> here</Link>.
                </Trans>
              </div>
            </div>
            <button
              className="button button--blue cookie-banner__close-button"
              id="js-cookieBannerCloseButton"
              data-qa="cookieCloseButton"
              onClick={onClickDismiss}
              title={t('cookie-banner_button-text')}
              type="button"
            >
              {t('cookie-banner_button-text')}
            </button>
          </div>
        </div>
      );
    }

    return null;
  }
}

export default withTranslation('cookie-banner')(CookieBanner);
