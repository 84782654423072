import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { useStore } from '../../contexts/storeContext';

const RATING_RATIO = 20;

const SingleBookingRatingAndComments: FC<WithTranslationType> = observer(({ t }) => {
  const {
    singleBooking: { booking, isPastBooking },
  } = useStore();

  const ratingStyle = { width: `${booking.rating * RATING_RATIO}%` };

  return isPastBooking && booking.rating ? (
    <div className="single-booking__booking-rating text-left">
      <h3 className="single-booking__ratings-section-title">
        {t('single-booking_ratings-section-title')}
      </h3>
      <div className="single-booking__stars">
        <div className="single-booking__stars--gold" style={ratingStyle} />
      </div>
      <p>{booking.ratingComments}</p>
    </div>
  ) : null;
});

export default withTranslation('single-booking')(SingleBookingRatingAndComments);
