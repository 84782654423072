import React, { ChangeEvent, useState } from 'react';
import Formsy from 'formsy-react';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import validators from '../../helpers/validators';
import { TRACKER_CATEGORY_FORGOT_PASSWORD } from '../../helpers/trackerConstants';
import { SERVER_ERROR_CODE } from '../../helpers/constants';

import FormField from '../../components/FormField';
import SubmitButton from './ForgotPasswordSubmitButton';

import './forgot-password-section.scss';

import forgotPasswordIcon from '../../../public/static/images/forgotpass.svg';

const ForgotPasswordSection = ({ t }: WithTranslationType): JSX.Element => {
  const { config, i18nUtils, session, tracker } = useStore();
  const [form, setState] = useState({
    email: '',
    isLoading: false,
    isSubmitted: false,
  });
  const [errors, setErrors] = useState({});

  const validations = validators(config, i18nUtils.umRegion);

  const onChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event) {
      setState({
        ...form,
        [event.target.name]: event.target.value,
      });

      setErrors({});
    }
  };

  const validate = (): boolean => {
    setState({
      ...form,
      isSubmitted: true,
    });

    return true;
  };

  const onValid = (): void => {
    if (form.isSubmitted) {
      validate();
    }
  };

  const setError = (name: string, value: any): void => {
    setErrors({
      ...errors,
      [name]: value,
    });
  };

  const onValidSubmit = (): void => {
    setState({
      ...form,
      isLoading: true,
    });

    if (validate()) {
      session
        .remindPassword(form)
        .then(data => {
          setState({
            ...form,
            isLoading: false,
            isSubmitted: true,
          });

          if (data.status === 'ERROR') {
            setError(SERVER_ERROR_CODE, data.code);

            tracker.track('Login error', {
              category: TRACKER_CATEGORY_FORGOT_PASSWORD,
              message: data && data.message,
            });
          } else {
            tracker.track('Logged in successfuly!', {
              category: TRACKER_CATEGORY_FORGOT_PASSWORD,
              data,
            });
          }
        })
        .catch(error => {
          tracker.track('Error occurred while trying to remind password', {
            category: TRACKER_CATEGORY_FORGOT_PASSWORD,
            error,
          });

          setState({
            ...form,
            isLoading: false,
          });
        });
    }
  };

  return (
    <div className="columns is-mobile forgot-password-section__container">
      <Formsy
        className="column forgot-password-section__form is-offset-1-mobile is-10-mobile is-offset-3-tablet is-6-tablet is-offset-4-desktop is-4-desktop"
        onValid={onValid}
        onValidSubmit={onValidSubmit}
        preventExternalInvalidation
      >
        <div className="text-center text-left-xs">
          <img
            alt="email"
            className="forgot-password-section__top-image"
            src={forgotPasswordIcon}
          />
          <h1 className="forgot-password-section__title">{t('forgot-password_title')}</h1>
          <p className="forgot-password-section__secondary-text">
            <span>{t('forgot-password_subtitle')}</span>
          </p>
        </div>
        <FormField
          id="js-email"
          labelText={t('forgot-password_email-label')}
          name="email"
          onChange={onChange}
          required
          t={t}
          type="email"
          validationError={t('forgot-password_invalid-email-error-message')}
          validationErrors={{
            isDefaultRequiredValue: t('forgot-password_required-email-field-error-message'),
          }}
          validations={{
            customIsEmail: validations.isEmail,
          }}
          value={form.email}
        />
        <SubmitButton errors={errors} loading={form.isLoading} submitted={form.isSubmitted} t={t} />
      </Formsy>
    </div>
  );
};

export default withTranslation('forgot-password')(ForgotPasswordSection);
