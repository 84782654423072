import React, { ChangeEvent, Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { computed, observable } from 'mobx';

import VerticalWithSpecialities, { Specialities } from './SignupVerticalWithSpecialities';
import { withTranslation, WithTranslationType } from '../../server/i18n';

interface Props extends WithTranslationType {
  city: string;
  config: {
    getValue: Function;
  };
  errors: {
    selectedTreatments: boolean;
  };
  formData: {
    verticals: {
      beauty: boolean;
      fitness: boolean;
      lifestyle: boolean;
      massage: boolean;
      medical: boolean;
    };
    selectedTreatments: {
      beauty: string[];
      fitness: string[];
      lifestyle: string[];
      massage: string[];
      medical: string[];
    };
  };
  i18nUtils: {
    locale: string;
    umRegion: string;
  };
  onChangeSpeciality: (param: string) => (event: ChangeEvent<Element>) => void;
  onChangeVertical: (event: ChangeEvent<Element>) => void;
  scrollTo: (param: string) => void;
  showVerticalsSignup: boolean;
}

export class QualifiedIn extends Component<Props, null> {
  @observable showVerticalsBeautyQuestion: boolean;
  @observable showVerticalsFitnessQuestion: boolean;
  @observable showVerticalsMassageQuestion: boolean;
  @observable showVerticalsMedicalQuestion: boolean;
  @observable showVerticalsLifestyleQuestion: boolean;
  beautyTreatmentsList: string[];
  fitnessTreatmentsList: string[];
  lifestyleTreatmentsList: string[];
  medicalTreatmentsList: string[];
  treatmentsList: string[];

  constructor(props: Props) {
    super(props);

    this.initialise(props);
  }

  componentDidMount(): void {
    const { scrollTo } = this.props;

    scrollTo('selectedTreatments');
  }

  componentDidUpdate(prevProps: Props): void {
    const { city } = this.props;
    // TODO this is stupid - replace
    if (city !== prevProps.city) {
      this.initialise(this.props);
    }
  }

  @computed get verticalsToShow(): JSX.Element[] {
    const {
      beautyTreatmentsList,
      fitnessTreatmentsList,
      lifestyleTreatmentsList,
      medicalTreatmentsList,
      showVerticalsBeautyQuestion,
      showVerticalsFitnessQuestion,
      showVerticalsLifestyleQuestion,
      showVerticalsMassageQuestion,
      showVerticalsMedicalQuestion,
      treatmentsList,
    } = this;
    const {
      formData: { selectedTreatments, verticals },
      onChangeSpeciality,
      onChangeVertical,
      t,
    } = this.props;

    const verticalOptions = [
      showVerticalsMassageQuestion && {
        checked: verticals.massage,
        id: 'js-vertical-massage',
        key: 'vertical-massage',
        labelTransKey: 'signup_verticals-select-treatments-title-massage',
        name: 'massage',
        treatmentsList,
        verticalTransKey: 'signup_vertical-label-massage',
      },
      showVerticalsBeautyQuestion && {
        checked: verticals.beauty,
        id: 'js-vertical-beauty',
        key: 'vertical-beauty',
        labelTransKey: 'signup_verticals-select-treatments-title-beauty',
        name: 'beauty',
        treatmentsList: beautyTreatmentsList,
        verticalTransKey: 'signup_vertical-label-beauty',
      },
      showVerticalsMedicalQuestion && {
        checked: verticals.medical,
        id: 'js-vertical-medical',
        key: 'vertical-medical',
        labelTransKey: 'signup_verticals-select-treatments-title-medical',
        name: 'medical',
        treatmentsList: medicalTreatmentsList,
        verticalTransKey: 'signup_vertical-label-medical',
      },
      showVerticalsFitnessQuestion && {
        checked: verticals.fitness,
        id: 'js-vertical-fitness',
        key: 'vertical-fitness',
        labelTransKey: 'signup_verticals-select-treatments-title-fitness',
        name: 'fitness',
        treatmentsList: fitnessTreatmentsList,
        verticalTransKey: 'signup_vertical-label-fitness',
      },
      showVerticalsLifestyleQuestion && {
        checked: verticals.lifestyle,
        id: 'js-vertical-lifestyle',
        key: 'vertical-lifestyle',
        labelTransKey: 'signup_verticals-select-treatments-title-lifestyle',
        name: 'lifestyle',
        treatmentsList: lifestyleTreatmentsList,
        verticalTransKey: 'signup_vertical-label-lifestyle',
      },
    ];

    return verticalOptions
      .filter(i => i)
      .map(props => (
        <VerticalWithSpecialities
          {...props}
          key={props.id}
          onChangeSpeciality={onChangeSpeciality}
          onChangeVertical={onChangeVertical}
          selectedTreatments={selectedTreatments}
          t={t}
        />
      ));
  }

  initialise(props: Props): void {
    // TODO this whole thing needs rippig out and replacing to make it observable
    const { city, config, i18nUtils } = props;

    const verticalBeautyAvailableCitiesList = config.getValue(
      'hero.portal.signup.verticals-beauty-available-cities-list',
      i18nUtils.umRegion,
    );
    const verticalFitnessAvailableCitiesList = config.getValue(
      'hero.portal.signup.verticals-fitness-available-cities-list',
      i18nUtils.umRegion,
    );
    const verticalMassageAvailableCitiesList = config.getValue(
      'hero.portal.signup.verticals-massage-available-cities-list',
      i18nUtils.umRegion,
    );
    const verticalMedicalAvailableCitiesList = config.getValue(
      'hero.portal.signup.verticals-medical-available-cities-list',
      i18nUtils.umRegion,
    );
    const verticalLifestyleAvailableCitiesList = config.getValue(
      'hero.portal.signup.verticals-lifestyle-available-cities-list',
      i18nUtils.umRegion,
    );

    this.treatmentsList = config.getValue(
      'hero.portal.signup.massage-treatment-list',
      i18nUtils.umRegion,
    );
    this.beautyTreatmentsList = config.getValue(
      'hero.portal.signup.beauty-treatments-list',
      i18nUtils.umRegion,
    );
    this.medicalTreatmentsList = config.getValue(
      'hero.portal.signup.medical-treatments-list',
      i18nUtils.umRegion,
    );
    this.fitnessTreatmentsList = config.getValue(
      'hero.portal.signup.fitness-treatments-list',
      i18nUtils.umRegion,
    );
    this.lifestyleTreatmentsList = config.getValue(
      'hero.portal.signup.lifestyle-treatments-list',
      i18nUtils.umRegion,
    );

    this.showVerticalsMassageQuestion = verticalMassageAvailableCitiesList.indexOf(city) > -1;
    this.showVerticalsBeautyQuestion = verticalBeautyAvailableCitiesList.indexOf(city) > -1;
    this.showVerticalsMedicalQuestion = verticalMedicalAvailableCitiesList.indexOf(city) > -1;
    this.showVerticalsFitnessQuestion = verticalFitnessAvailableCitiesList.indexOf(city) > -1;
    this.showVerticalsLifestyleQuestion = verticalLifestyleAvailableCitiesList.indexOf(city) > -1;
  }

  render(): JSX.Element {
    const { treatmentsList, verticalsToShow } = this;

    const { errors, formData, onChangeSpeciality, showVerticalsSignup, t } = this.props;

    const { selectedTreatments } = formData;

    return (
      <div id="js-treatments-list">
        <p>{t('signup_qualified-in-question')}</p>
        {errors.selectedTreatments && (
          <div
            className="signup__error signup__error--with-margin-bottom"
            data-qa="qualified-in-error"
          >
            <span>{t('signup_select-treatment-error')}</span>
          </div>
        )}
        {showVerticalsSignup ? (
          <Fragment>
            {verticalsToShow}
            {verticalsToShow && verticalsToShow.length > 0 && <hr />}
          </Fragment>
        ) : (
          <Fragment>
            <Specialities
              name="massage"
              onChangeSpeciality={onChangeSpeciality}
              selectedTreatments={selectedTreatments}
              treatmentsList={treatmentsList}
            />
            <hr />
          </Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation('signup')(observer(QualifiedIn));
