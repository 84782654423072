import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import validators from '../../helpers/validators';

import { IPersonalDetails } from '../../../types/signup';

import FormField from '../../components/FormField';

interface Props {
  config: {
    getValue: Function;
  };
  i18nUtils: {
    locale: string;
    umRegion: string;
  };
  onChange: Function;
  personalDetails: IPersonalDetails;
  t: (param: string, options?: Record<string, any>) => string;
}

const PersonalDetails = observer(({ config, i18nUtils, onChange, personalDetails, t }: Props): JSX.Element => {
  const passwordMinLength = config.getValue('locale.password-min-length', i18nUtils.umRegion);
  const hideLastNameQuestion = config.getValue(
    'hero.portal.signup.hide-last-name-question',
    i18nUtils.umRegion,
  );

  const validations = validators(config, i18nUtils.umRegion);

  return (
    <div
      className="signup-form__personal-details"
      data-qa="personal-details-wrapper"
      data-testid="personalDetailsWrapper"
    >
      {hideLastNameQuestion ? (
        <FormField
          error={personalDetails.legalName.error}
          id="js-legal-name"
          labelText={t('signup_legal-name-label')}
          name="legalName"
          onChange={onChange('legalName')}
          required
          t={t}
          validationError={t('signup_invalid-legal-name-error-message')}
          validationErrors={{
            isDefaultRequiredValue: t('signup_required-legal-name-field-error-message'),
          }}
          value={personalDetails.legalName.value}
        />
      ) : (
        <Fragment>
          <FormField
            id="js-first-name"
            labelText={t('signup_first-name-label')}
            name="firstName"
            onChange={onChange('firstName')}
            required
            t={t}
            validationError={t('signup_invalid-first-name-error-message')}
            validationErrors={{
              isDefaultRequiredValue: t('signup_required-first-name-field-error-message'),
            }}
            value={personalDetails.firstName.value}
          />
          <FormField
            id="js-last-name"
            labelText={t('signup_last-name-label')}
            name="lastName"
            onChange={onChange('lastName')}
            required
            t={t}
            validationError={t('signup_invalid-last-name-error-message')}
            validationErrors={{
              isDefaultRequiredValue: t('signup_required-last-name-field-error-message'),
            }}
            value={personalDetails.lastName.value}
          />
        </Fragment>
      )}
      <FormField
        id="js-mobile-number"
        labelText={t('signup_mobile-number-label')}
        name="mobileNumber"
        onChange={onChange('mobileNumber')}
        required
        t={t}
        type="tel"
        validationError={t('signup_invalid-mobile-number-error-message')}
        validationErrors={{
          isDefaultRequiredValue: t('signup_required-mobile-number-field-error-message'),
        }}
        validations={{
          isPhone: validations.isPhone,
        }}
        value={personalDetails.mobileNumber.value}
      />
      <FormField
        id="js-email"
        labelText={t('signup_email-label')}
        name="email"
        onChange={onChange('email')}
        required
        t={t}
        type="email"
        validationError={t('signup_invalid-email-error-message')}
        validationErrors={{
          isDefaultRequiredValue: t('signup_required-email-field-error-message'),
        }}
        validations={{
          customIsEmail: validations.isEmail,
        }}
        value={personalDetails.email.value}
      />
      <FormField
        id="js-password"
        labelText={t('signup_password-label')}
        name="password"
        onChange={onChange('password')}
        required
        showPasswordButton
        t={t}
        type="password"
        validationErrors={{
          isDefaultRequiredValue: t('signup_required-password-field-error-message'),
          isPassword: t('signup_invalid-password-error-message', {
            passwordMinLength,
          }),
        }}
        validations={{
          isPassword: validations.isPassword,
        }}
        value={personalDetails.password.value}
      />
      <p className="signup-form__pull-left-question signup-form__pull-left-question--password-hint">
        {t('signup_password-hint')}
      </p>
      <FormField
        id="js-referral-code"
        labelText={t('signup_referral-code-label')}
        name="referralCode"
        onChange={onChange('referralCode')}
        t={t}
        validationErrors={{
          isReferralCode: t('signup_referral-code-field-error-message'),
        }}
        validations={{
          isReferralCode: validations.isReferralCode,
        }}
        value={personalDetails.referralCode.value}
      />
      <hr />
    </div>
  );
});

export default PersonalDetails;
