import { StoreType } from '../stores/store';
import { IFormData, IApplicationFormData } from '../../types/signup';

interface ITreatmentsAndVerticals {
  treatments: string[];
  verticalIds: string[];
}

export function getTreatmentsAndVerticalsOld(
  rootStore: StoreType,
  data: IFormData,
): ITreatmentsAndVerticals {
  const treatments = [];
  const verticalIds: string[] = Object.keys(data.selectedTreatments).filter(
    key => data.selectedTreatments[key].length > 0,
  );
  const medicalIndex = verticalIds.indexOf('medical');

  if (medicalIndex > -1 && data.selectedTreatments && data.selectedTreatments.medical) {
    const requiredOsteoTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-medical-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    const requiredPhysioTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-physio-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    verticalIds.splice(medicalIndex, 1);
    const hasOsteoTreatments: boolean =
      requiredOsteoTreatments &&
      requiredOsteoTreatments.some((i: string): boolean =>
        data.selectedTreatments.medical.includes(i),
      );
    const hasPhysioTreatments: boolean =
      requiredPhysioTreatments &&
      requiredPhysioTreatments.some((i: string): boolean =>
        data.selectedTreatments.medical.includes(i),
      );

    if (hasOsteoTreatments) {
      verticalIds.push('osteo');
      treatments.push('VERTICAL_OSTEO');
    }

    if (hasPhysioTreatments) {
      verticalIds.push('physio');
      treatments.push('VERTICAL_PHYSIO');
    }

    treatments.push(...data.selectedTreatments.medical);
  }

  const beautyIndex = verticalIds.indexOf('beauty');

  if (beautyIndex > -1 && data.selectedTreatments && data.selectedTreatments.beauty) {
    const requiredHairTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-hair-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    const requiredMakeupTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-makeup-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    const requiredWaxingTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-waxing-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    const requiredBrowsTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-brows-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    const requiredNailsTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-nails-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    verticalIds.splice(beautyIndex, 1);
    const hasHairTreatments: boolean =
      requiredHairTreatments &&
      requiredHairTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasMakeupTreatments: boolean =
      requiredMakeupTreatments &&
      requiredMakeupTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasWaxingTreatments: boolean =
      requiredWaxingTreatments &&
      requiredWaxingTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasBrowsTreatments: boolean =
      requiredBrowsTreatments &&
      requiredBrowsTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasNailsTreatments: boolean =
      requiredNailsTreatments &&
      requiredNailsTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );

    if (hasHairTreatments) {
      verticalIds.push('hair');
      treatments.push('VERTICAL_HAIR');
    }

    if (hasMakeupTreatments) {
      verticalIds.push('makeup');
      treatments.push('VERTICAL_MAKEUP');
    }

    if (hasWaxingTreatments) {
      verticalIds.push('waxing');
      treatments.push('VERTICAL_WAXING');
    }

    if (hasBrowsTreatments) {
      verticalIds.push('brows');
      treatments.push('VERTICAL_BROWS');
    }

    if (hasNailsTreatments) {
      verticalIds.push('nails');
      treatments.push('VERTICAL_NAILS');
    }

    treatments.push(...data.selectedTreatments.beauty);
  }

  const fitnessIndex = verticalIds.indexOf('fitness');

  if (fitnessIndex > -1 && data.selectedTreatments && data.selectedTreatments.fitness) {
    const requiredFitnessTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-fitness-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    const requiredYogaTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-yoga-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    const requiredPersonalTrainingTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-personal-training-treatments',
      rootStore.i18nUtils.umRegion,
      data.city,
    );
    verticalIds.splice(fitnessIndex, 1);
    const hasFitnessTreatments: boolean =
      requiredFitnessTreatments &&
      requiredFitnessTreatments.some((i: string): boolean =>
        data.selectedTreatments.fitness.includes(i),
      );
    const hasYogaTreatments: boolean =
      requiredYogaTreatments &&
      requiredYogaTreatments.some((i: string): boolean =>
        data.selectedTreatments.fitness.includes(i),
      );
    const hasPersonalTrainingTreatments: boolean =
      requiredPersonalTrainingTreatments &&
      requiredPersonalTrainingTreatments.some((i: string): boolean =>
        data.selectedTreatments.fitness.includes(i),
      );

    if (hasFitnessTreatments) {
      treatments.push('VERTICAL_FITNESS');
    }

    if (hasYogaTreatments) {
      verticalIds.push('yoga');
      treatments.push('VERTICAL_YOGA');
    }

    if (hasPersonalTrainingTreatments) {
      verticalIds.push('personal-training');
      treatments.push('VERTICAL_PERSONAL_TRAINING');
    }

    treatments.push(...data.selectedTreatments.fitness);
  }

  const massageIndex = verticalIds.indexOf('massage');

  if (massageIndex > -1 && data.selectedTreatments && data.selectedTreatments.massage) {
    treatments.push('VERTICAL_MASSAGE');
    treatments.push(...data.selectedTreatments.massage);
  }

  const lifestyleIndex = verticalIds.indexOf('lifestyle');

  if (lifestyleIndex > -1 && data.selectedTreatments && data.selectedTreatments.lifestyle) {
    verticalIds.splice(lifestyleIndex, 1);
    treatments.push('VERTICAL_LIFESTYLE');
    treatments.push(...data.selectedTreatments.lifestyle);
  }

  return {
    treatments,
    verticalIds,
  };
}

export function getTreatmentsAndVerticals(
  rootStore: StoreType,
  city: string,
  data: IApplicationFormData,
): ITreatmentsAndVerticals {
  const treatments = [];
  const verticalIds: string[] = Object.keys(data.selectedTreatments).filter(
    key => data.selectedTreatments[key].length > 0,
  );
  const medicalIndex = verticalIds.indexOf('medical');

  if (medicalIndex > -1 && data.selectedTreatments && data.selectedTreatments.medical) {
    const requiredOsteoTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-medical-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    const requiredPhysioTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-physio-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    verticalIds.splice(medicalIndex, 1);
    const hasOsteoTreatments: boolean =
      requiredOsteoTreatments &&
      requiredOsteoTreatments.some((i: string): boolean =>
        data.selectedTreatments.medical.includes(i),
      );
    const hasPhysioTreatments: boolean =
      requiredPhysioTreatments &&
      requiredPhysioTreatments.some((i: string): boolean =>
        data.selectedTreatments.medical.includes(i),
      );

    if (hasOsteoTreatments) {
      verticalIds.push('osteo');
      treatments.push('VERTICAL_OSTEO');
    }

    if (hasPhysioTreatments) {
      verticalIds.push('physio');
      treatments.push('VERTICAL_PHYSIO');
    }

    treatments.push(...data.selectedTreatments.medical);
  }

  const beautyIndex = verticalIds.indexOf('beauty');

  if (beautyIndex > -1 && data.selectedTreatments && data.selectedTreatments.beauty) {
    const requiredHairTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-hair-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    const requiredMakeupTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-makeup-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    const requiredWaxingTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-waxing-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    const requiredBrowsTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-brows-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    const requiredNailsTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-nails-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    verticalIds.splice(beautyIndex, 1);
    const hasHairTreatments: boolean =
      requiredHairTreatments &&
      requiredHairTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasMakeupTreatments: boolean =
      requiredMakeupTreatments &&
      requiredMakeupTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasWaxingTreatments: boolean =
      requiredWaxingTreatments &&
      requiredWaxingTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasBrowsTreatments: boolean =
      requiredBrowsTreatments &&
      requiredBrowsTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );
    const hasNailsTreatments: boolean =
      requiredNailsTreatments &&
      requiredNailsTreatments.some((i: string): boolean =>
        data.selectedTreatments.beauty.includes(i),
      );

    if (hasHairTreatments) {
      verticalIds.push('hair');
      treatments.push('VERTICAL_HAIR');
    }

    if (hasMakeupTreatments) {
      verticalIds.push('makeup');
      treatments.push('VERTICAL_MAKEUP');
    }

    if (hasWaxingTreatments) {
      verticalIds.push('waxing');
      treatments.push('VERTICAL_WAXING');
    }

    if (hasBrowsTreatments) {
      verticalIds.push('brows');
      treatments.push('VERTICAL_BROWS');
    }

    if (hasNailsTreatments) {
      verticalIds.push('nails');
      treatments.push('VERTICAL_NAILS');
    }

    treatments.push(...data.selectedTreatments.beauty);
  }

  const fitnessIndex = verticalIds.indexOf('fitness');

  if (fitnessIndex > -1 && data.selectedTreatments && data.selectedTreatments.fitness) {
    const requiredFitnessTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-fitness-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    const requiredYogaTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-yoga-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    const requiredPersonalTrainingTreatments: string[] = rootStore.config.getValue(
      'hero.portal.signup.required-personal-training-treatments',
      rootStore.i18nUtils.umRegion,
      city,
    );
    verticalIds.splice(fitnessIndex, 1);
    const hasFitnessTreatments: boolean =
      requiredFitnessTreatments &&
      requiredFitnessTreatments.some((i: string): boolean =>
        data.selectedTreatments.fitness.includes(i),
      );
    const hasYogaTreatments: boolean =
      requiredYogaTreatments &&
      requiredYogaTreatments.some((i: string): boolean =>
        data.selectedTreatments.fitness.includes(i),
      );
    const hasPersonalTrainingTreatments: boolean =
      requiredPersonalTrainingTreatments &&
      requiredPersonalTrainingTreatments.some((i: string): boolean =>
        data.selectedTreatments.fitness.includes(i),
      );

    if (hasFitnessTreatments) {
      treatments.push('VERTICAL_FITNESS');
    }

    if (hasYogaTreatments) {
      verticalIds.push('yoga');
      treatments.push('VERTICAL_YOGA');
    }

    if (hasPersonalTrainingTreatments) {
      verticalIds.push('personal-training');
      treatments.push('VERTICAL_PERSONAL_TRAINING');
    }

    treatments.push(...data.selectedTreatments.fitness);
  }

  const massageIndex = verticalIds.indexOf('massage');

  if (massageIndex > -1 && data.selectedTreatments && data.selectedTreatments.massage) {
    treatments.push('VERTICAL_MASSAGE');
    treatments.push(...data.selectedTreatments.massage);
  }

  const lifestyleIndex = verticalIds.indexOf('lifestyle');

  if (lifestyleIndex > -1 && data.selectedTreatments && data.selectedTreatments.lifestyle) {
    verticalIds.splice(lifestyleIndex, 1);
    treatments.push('VERTICAL_LIFESTYLE');
    treatments.push(...data.selectedTreatments.lifestyle);
  }

  return {
    treatments,
    verticalIds,
  };
}
