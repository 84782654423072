import React, { FC } from 'react';
import { withTranslation, WithTranslationType } from '../../server/i18n';

interface Props extends WithTranslationType {
  bookingTotal: string;
}

const BookingTotal: FC<Props> = ({ bookingTotal, t }: Props) => {
  return (
    <div className="single-booking__booking-item single-booking__booking-item--total">
      <div className="columns is-multiline is-mobile">
        <div className="column is-7-mobile is-6-tablet text-left">
          {t('single-booking_total-row-label')}
        </div>
        <div className="column is-5-mobile is-6-tablet text-right" data-qa="booking-total-value">
          {bookingTotal}
        </div>
      </div>
    </div>
  );
};

export default withTranslation('single-booking')(BookingTotal);
