/*
 *
 *  Link component that automatically adds the locale to the link
 *  We need to do it this way, in order to be able to use the Link component from next-route, for pretty urls
 *
 */
import React, { ReactNode, FunctionComponent, MouseEvent } from 'react';

import { Link } from '../server/routes';

interface LinkProps {
  children: ReactNode;
  className?: string;
  disableAddLocale?: boolean;
  id?: string;
  lng?: string;
  prefetch?: boolean;
  route: string;
  target?: string;
}

const LinkLocale: FunctionComponent<LinkProps> = (props: LinkProps) => {
  const {
    children,
    className,
    disableAddLocale = false,
    id,
    lng,
    prefetch,
    route,
    target,
    ...rest
  } = props;
  const language = lng ? `/${lng}` : '';
  const localeInfo = !disableAddLocale ? language : '';

  return (
    <Link prefetch={prefetch} route={`${localeInfo}${route}`}>
      <a
        className={`anchor ${className}`}
        href={`${localeInfo}${route}`}
        id={id}
        target={target}
        {...rest}
      >
        {children}
      </a>
    </Link>
  );
};

export default LinkLocale;
