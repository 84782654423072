import React, { ReactNode } from 'react';
import { observer, inject } from 'mobx-react';
import { observable } from 'mobx';
import { Trans, withTranslation, WithTranslationType } from '../server/i18n';

import ProgressiveImage from './ProgressiveImage';

import './footer.scss';

interface FooterProps extends WithTranslationType {
  config?: {
    getValue: (
      key: string,
      filterCountry?: string,
      filterCity?: string,
    ) => boolean | string | number;
  };
  i18nUtils?: {
    locale: string;
    umRegion: string;
  };
}

@inject(({ store: { config, i18nUtils } }) => ({
  config,
  i18nUtils,
}))
@observer
class Footer extends React.Component<FooterProps, null> {
  @observable downloadAppsImages;
  @observable downloadAppLink;

  constructor(props: FooterProps) {
    super(props);
    const { config, i18nUtils } = this.props;

    this.downloadAppLink = config.getValue('locale.adjust-app-url', i18nUtils.umRegion);

    this.downloadAppsImages = {
      'en-gb': {
        appleStore: '/static/images/appstore_en.svg',
        playstore: '/static/images/gplay_en.png',
      },
      'fr-fr': {
        appleStore: '/static/images/appstore_fr.svg',
        playstore: '/static/images/gplay_fr.png',
      },
    };
  }

  render(): ReactNode {
    const {
      i18n: { language },
      i18nUtils,
      t,
    } = this.props;
    const { downloadAppLink, downloadAppsImages } = this;

    return (
      <div className="footer" id="js-footer">
        <div className="columns is-vcentered is-variable is-4">
          <div className="column footer__logo-holder">
            <img
              alt={t('footer_urban-logo-alt')}
              className="footer__logo"
              src="/static/images/urban-logo-hero.svg"
            />
          </div>
          <div className="column">
            <div className="footer__download-app">
              <a
                className="anchor"
                href={downloadAppLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <ProgressiveImage
                  altText={t('footer_applestore-logo-alt')}
                  className="footer__store-image"
                  disableWebp
                  highResImg={downloadAppsImages[language].appleStore}
                  highResImg2x={downloadAppsImages[language].appleStore}
                  lowResImg={downloadAppsImages[language].appleStore}
                />
              </a>
              <a
                className="anchor"
                href={downloadAppLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                <ProgressiveImage
                  altText={t('footer_playstore-logo-alt')}
                  className="footer__store-image"
                  disableWebp
                  highResImg={downloadAppsImages[language].playstore}
                  highResImg2x={downloadAppsImages[language].playstore}
                  lowResImg={downloadAppsImages[language].playstore}
                />
              </a>
            </div>
            <p className="footer__text">
              <Trans t={t} i18nKey="footer_b2c-link-note">
                Looking for a therapist? Please visit
                <a
                  className="anchor footer__b2c-link"
                  href={`https://urban.co/${i18nUtils.locale}/`}
                  id="js-footerB2CLink"
                >
                  urban.co
                </a>
              </Trans>
            </p>
            <p className="footer__text">{t('footer_copyright-note')}</p>
          </div>
          <div className="column footer__social-links">
            <a
              className="anchor footer__social-icon-link"
              href={t('footer_facebook-link')}
              id="js-footerFacebookLink"
              data-qa="footerFacebook"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ProgressiveImage
                altText={t('footer_facebook-logo-alt')}
                className="footer__social-icon"
                disableWebp
                highResImg="/static/images/icons/social_fb.svg"
                highResImg2x="/static/images/icons/social_fb.svg"
                lowResImg="/static/images/icons/social_fb.svg"
              />
            </a>
            <a
              className="anchor footer__social-icon-link"
              href={t('footer_twitter-link')}
              id="js-footerTwitterLink"
              data-qa="footerTwitter"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ProgressiveImage
                altText={t('footer_twitter-logo-alt')}
                className="footer__social-icon"
                disableWebp
                highResImg="/static/images/icons/social_tw.svg"
                highResImg2x="/static/images/icons/social_tw.svg"
                lowResImg="/static/images/icons/social_tw.svg"
              />
            </a>
            <a
              className="anchor footer__social-icon-link"
              href={t('footer_instagram-link')}
              id="js-footerInstagramLink"
              data-qa="footerInstagram"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ProgressiveImage
                altText={t('footer_instagram-logo-alt')}
                className="footer__social-icon"
                disableWebp
                highResImg="/static/images/icons/social_ig.svg"
                highResImg2x="/static/images/icons/social_ig.svg"
                lowResImg="/static/images/icons/social_ig.svg"
              />
            </a>
            <a
              className="anchor footer__social-icon-link"
              href={t('footer_linkedin-link')}
              id="js-footerLinkedinLink"
              data-qa="footerLinkedin"
              rel="noopener noreferrer"
              target="_blank"
            >
              <ProgressiveImage
                altText={t('footer_linkedin-logo-alt')}
                className="footer__social-icon"
                disableWebp
                highResImg="/static/images/icons/social_in.svg"
                highResImg2x="/static/images/icons/social_in.svg"
                lowResImg="/static/images/icons/social_in.svg"
              />
            </a>
          </div>
        </div>
        {language === 'fr-fr' && (
          <div className="footer__legal-comment">
            <p className="footer__legal-comment-text">{t('footer_legal-comment-line-one')}</p>
            <p className="footer__legal-comment-text">{t('footer_legal-comment-line-two')}</p>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('footer')(Footer);
