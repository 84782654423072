import React, { FC, useState } from 'react';
import cn from 'classnames';
import Link from '../../Link';
import { WithTranslationType, withTranslation } from '../../../server/i18n';
import { useStore } from '../../../contexts/storeContext';

import config from '../../../helpers/config';

import chevronDownIcon from '../../../../public/static/images/chevron_down.svg';

const { publicRuntimeConfig } = config;
const { HTTP_HOST, SALESFORCE_FAQ_BASE_URL } = publicRuntimeConfig;

interface MobileMenuProps extends WithTranslationType {
  activeMenuItem: string;
  hideCommunity: boolean;
  hideReferral: boolean;
  hideStatements: boolean;
}

const MobileMenu: FC<MobileMenuProps> = ({
  activeMenuItem,
  hideCommunity,
  hideReferral,
  hideStatements,
  t,
}: MobileMenuProps) => {
  const { config, i18nUtils } = useStore();

  const [showMenu, setShowMenu] = useState(false);
  const [showAccountDropdown, setShowAccountDropdown] = useState(false);

  const communityLinkActive = config.getValue(
    'hero.feature.show-community-link',
    i18nUtils.umRegion,
  );
  const communityLinkUrl = config.getValue('hero.feature.community-url', i18nUtils.umRegion);
  const educationPortal = config.getValue('hero.has-education-portal', i18nUtils.umRegion);
  const promoteProfileActive = config.getValue(
    'hero.feature.promote-profile.enabled',
    i18nUtils.umRegion,
  );
  const promotedReviewsActive = config.getValue(
    'hero.feature.promoted-reviews.enabled',
    i18nUtils.umRegion,
  );

  const onBgShadowClick = (): void => {
    setShowMenu(false);
  };

  const toggleShowMenu = (): void => {
    setShowMenu(!showMenu);
  };

  const toggleAccountDropdown = (): void => {
    setShowAccountDropdown(!showAccountDropdown);
  };

  return (
    <>
      <div className={cn('bg-shadow', !showMenu && 'hide')} onClick={onBgShadowClick} />
      <a
        href="#"
        className={cn('anchor visible-xs', 'burger-menu-icon', showMenu && 'open')}
        onClick={toggleShowMenu}
      >
        <span />
        <span />
        <span />
      </a>

      <nav className={cn('portal-navbar__mobile-menu', !showMenu && 'hide')}>
        <ul className="portal-navbar__mobile-menu-container">
          <li className="portal-navbar__mobile-menu-item">
            <Link
              className={cn(
                'portal-navbar__mobile-menu-link',
                activeMenuItem === 'bookings' && 'portal-navbar__mobile-menu-link--active',
              )}
              prefetch={false}
              route="/bookings"
            >
              {t('portal-navbar_bookings')}
            </Link>
          </li>
          <li className="portal-navbar__mobile-menu-item">
            <Link
              className={cn(
                'portal-navbar__mobile-menu-link',
                activeMenuItem === 'diary' && 'portal-navbar__mobile-menu-link--active',
              )}
              prefetch={false}
              route="/diary"
            >
              {t('portal-navbar_diary')}
            </Link>
          </li>
          <li className="portal-navbar__mobile-menu-item">
            <a
              className={cn(
                'anchor portal-navbar__mobile-menu-link',
                activeMenuItem === 'perks' && 'portal-navbar__mobile-sub-menu-item--active',
              )}
              href={`https://${HTTP_HOST}/perks`}
            >
              {t('portal-navbar_perks')}
            </a>
          </li>
          {!hideReferral ? (
            <li className="portal-navbar__mobile-menu-item">
              <a
                className={cn(
                  'anchor portal-navbar__mobile-menu-link',
                  activeMenuItem === 'refer' && 'portal-navbar__mobile-menu-link--active',
                )}
                href={`https://${HTTP_HOST}/refer`}
              >
                {t('portal-navbar_referral')}
              </a>
            </li>
          ) : null}
          {!hideCommunity ? (
            <li className="portal-navbar__mobile-menu-item">
              <a
                className={cn(
                  'anchor portal-navbar__mobile-menu-link',
                  activeMenuItem === 'resources' && 'portal-navbar__mobile-sub-menu-item--active',
                )}
                href={`https://${HTTP_HOST}/resources`}
              >
                {t('portal-navbar_resources')}
              </a>
            </li>
          ) : null}
          <li className="portal-navbar__mobile-menu-item portal-navbar__mobile-menu-sub-menu-trigger">
            <img
              alt="dropdown-arrow"
              className={cn(
                'portal-navbar__chevron',
                showAccountDropdown && 'portal-navbar__chevron--active',
              )}
              src={chevronDownIcon}
            />
            <a
              className="anchor portal-navbar__mobile-menu-link"
              href="#"
              onClick={toggleAccountDropdown}
            >
              {t('portal-navbar_account')}
            </a>
          </li>
          {showAccountDropdown && (
            <>
              <li
                className={cn(
                  'portal-navbar__subcategory',
                  activeMenuItem === '' && 'portal-navbar__mobile-sub-menu-item--active',
                )}
              >
                <a
                  className="anchor portal-navbar__mobile-sub-menu-link"
                  href={`https://${HTTP_HOST}/account`}
                >
                  {t('portal-navbar_account-details')}
                </a>
              </li>
              {communityLinkActive && !hideCommunity && (
                <li
                  className={cn(
                    'portal-navbar__subcategory',
                    activeMenuItem === '' && 'portal-navbar__mobile-sub-menu-item--active',
                  )}
                >
                  <a
                    className="anchor portal-navbar__mobile-sub-menu-link"
                    href={communityLinkUrl}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {t('portal-navbar_account-community')}
                  </a>
                </li>
              )}
              <li
                className={cn(
                  'portal-navbar__subcategory',
                  activeMenuItem === '' && 'portal-navbar__mobile-sub-menu-item--active',
                )}
              >
                <a
                  className="anchor portal-navbar__mobile-sub-menu-link"
                  href={`https://${HTTP_HOST}/documents`}
                >
                  {t('portal-navbar_account-documents')}
                </a>
              </li>
              <li
                className={cn(
                  'portal-navbar__subcategory',
                  activeMenuItem === '' && 'portal-navbar__mobile-sub-menu-item--active',
                )}
              >
                {promotedReviewsActive ? (
                  <a
                    className="anchor portal-navbar__mobile-sub-menu-link"
                    href={`https://${HTTP_HOST}/reviews`}
                  >
                    {t('portal-navbar_account-reviews')}
                  </a>
                ) : (
                  <a
                    className="anchor portal-navbar__mobile-sub-menu-link"
                    href={`https://${HTTP_HOST}/ratings`}
                  >
                    {t('portal-navbar_account-ratings')}
                  </a>
                )}
              </li>
              <li
                className={cn(
                  'portal-navbar__subcategory',
                  activeMenuItem === '' && 'portal-navbar__mobile-sub-menu-item--active',
                )}
              >
                <Link
                  className={cn(
                    'portal-navbar__mobile-sub-menu-link',
                    activeMenuItem === 'service-areas' &&
                      'portal-navbar__mobile-sub-menu-link--active',
                  )}
                  prefetch={false}
                  route="/service-areas"
                >
                  {t('portal-navbar_account-service-areas')}
                </Link>
              </li>
              {!hideStatements ? (
                <li
                  className={cn(
                    'portal-navbar__subcategory',
                    activeMenuItem === '' && 'portal-navbar__mobile-sub-menu-item--active',
                  )}
                >
                  <a
                    className="anchor portal-navbar__mobile-sub-menu-link"
                    href={`https://${HTTP_HOST}/statements`}
                  >
                    {t('portal-navbar_account-statements')}
                  </a>
                </li>
              ) : null}
              {promoteProfileActive && (
                <li
                  className={cn(
                    'portal-navbar__subcategory',
                    activeMenuItem === '' && 'portal-navbar__mobile-sub-menu-item--active',
                  )}
                >
                  <a
                    className="anchor portal-navbar__mobile-sub-menu-link"
                    href={`https://${HTTP_HOST}/promote-profile`}
                  >
                    {t('portal-navbar_account-promote-profile')}
                  </a>
                </li>
              )}
            </>
          )}
          <li className="portal-navbar__mobile-menu-item">
            <a
              className={cn(
                'anchor portal-navbar__mobile-menu-link',
                activeMenuItem === 'faq' && 'portal-navbar__mobile-menu-link--active',
              )}
              href={
                i18nUtils.umRegion === 'UK' ? SALESFORCE_FAQ_BASE_URL : `https://${HTTP_HOST}/faq`
              }
            >
              {t('portal-navbar_help')}
            </a>
          </li>
          {educationPortal && (
            <li>
              <a
                className={cn(
                  'anchor portal-navbar__mobile-menu-link',
                  activeMenuItem === 'education' && 'portal-navbar__mobile-menu-link--active',
                )}
                href={`https://${HTTP_HOST}/education`}
              >
                {t('portal-navbar_education-portal')}
              </a>
            </li>
          )}
        </ul>

        {promoteProfileActive && (
          <a
            className="anchor portal-navbar__btn portal-navbar__btn--promote-btn-mobile"
            href={`https://${HTTP_HOST}/promote-profile`}
          >
            {t('portal-navbar_promote')}
          </a>
        )}
      </nav>
    </>
  );
};

export default withTranslation('portal-navbar')(MobileMenu);
