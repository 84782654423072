import React from 'react';
import { observable } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Instance } from 'mobx-state-tree';
import { dateFormat } from '../../helpers';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import { SingleBooking } from '../../stores/SingleBooking';

import bookingCalendarIcon from '../../../public/static/images/booking_calendar.svg';
import profileIcon from '../../../public/static/images/1person.svg';
import locationIcon from '../../../public/static/images/location.svg';
import treatmentIcon from '../../../public/static/images/treatment.svg';
import notesIcon from '../../../public/static/images/notes.svg';
import stairsIcon from '../../../public/static/images/stairs.svg';
import catIcon from '../../../public/static/images/cat.svg';
import dogIcon from '../../../public/static/images/dog.svg';
import carIcon from '../../../public/static/images/car.svg';

const LONG_DATE_FORMAT = 'EEEE d MMMM yyyy';
const TIME_FORMAT = 'HH:mm';

interface SingleBookingSummaryProps extends WithTranslationType {
  i18nUtils: {
    datesLocale: string;
  };
  singleBooking?: Instance<typeof SingleBooking>;
}

@inject(({ store: { i18nUtils, singleBooking } }) => ({
  i18nUtils,
  singleBooking,
}))
@observer
class SingleBookingSummary extends React.Component<SingleBookingSummaryProps, null> {
  @observable stairs = '';

  componentDidMount() {
    const {
      singleBooking: { booking },
    } = this.props;

    switch (booking.stairs) {
      case 'ground':
        this.stairs = 'single-booking_floors-ground';
        break;

      case '1':
        this.stairs = 'single-booking_floors-first';
        break;

      case '2':
        this.stairs = 'single-booking_floors-second';
        break;

      case '3':
        this.stairs = 'single-booking_floors-third';
        break;

      case '4':
        this.stairs = 'single-booking_floors-fourth';
        break;

      case '5+':
        this.stairs = 'single-booking_floors-five-or-more';
        break;
      default:
        this.stairs = 'single-booking_details-floors-unknown';
    }
  }

  render() {
    const {
      i18nUtils,
      singleBooking: { booking, isPastBooking },
      t,
    } = this.props;
    const { userComments } = booking;
    const { stairs } = this;

    return (
      <div className="single-booking__summary columns is-multiline is-mobile">
        <div className="single-booking__summary-item column is-12">
          <img alt="calendar" src={bookingCalendarIcon} />
          <div className="single-booking__summary-item-wrapper">
            <div
              className="single-booking__summary-item-title capitalize"
              data-qa="single-booking-summary-long-date"
              data-testid="single-booking-summary-long-date"
            >
              {dateFormat(booking.startsAt, LONG_DATE_FORMAT, booking.timezone, i18nUtils.datesLocale)}
            </div>
            <div
              className="single-booking__summary-item-subtitle"
              data-qa="single-booking-summary-start-time"
              data-testid="single-booking-summary-start-time"
            >
              {dateFormat(booking.startsAt, TIME_FORMAT, booking.timezone)}-
              {dateFormat(booking.endsAt, TIME_FORMAT, booking.timezone)}
            </div>
          </div>
          <hr />
        </div>
        {!isPastBooking && (
          <div className="single-booking__summary-item column is-12">
            <img alt="location" src={locationIcon} />
            <div className="single-booking__summary-item-wrapper">
              <div
                className="single-booking__summary-item-title"
                data-qa="single-booking-summary-address"
                data-testid="single-booking-summary-address"
              >
                {booking.address1}
                {booking.address2 && <span>, {booking.address2}</span>}
              </div>
              <div
                className="single-booking__summary-item-subtitle"
                data-qa="single-booking-summary-postcode"
                data-testid="single-booking-summary-postcode"
              >
                {booking.postcode && `${booking.postcode},`} {booking.city}
              </div>
            </div>
            <hr />
          </div>
        )}
        <div className="single-booking__summary-item single-booking__summary-item--left-column column is-12-mobile is-6-tablet">
          <img alt="profile" src={profileIcon} />
          <div className="single-booking__summary-item-wrapper">
            <div
              className="single-booking__summary-item-title single-booking__summary-item-title--customer-name"
              data-qa="single-booking-summary-client-name"
              data-testid="single-booking-summary-client-name"
            >
              {booking.user.name}
            </div>
          </div>
          <hr />
        </div>
        <div className="single-booking__summary-item single-booking__summary-item--right-column column is-12-mobile is-6-tablet">
          <img alt="treatment" src={treatmentIcon} />
          <div className="single-booking__summary-item-wrapper">
            <div
              className="single-booking__summary-item-title"
              data-qa="single-booking-summary-treatment-name"
              data-testid="single-booking-summary-treatment-name"
            >
              {booking.treatmentName}
            </div>
          </div>
          <hr />
        </div>
        {!isPastBooking && (
          <>
            <div className="single-booking__summary-item single-booking__summary-item--address-notes column is-12">
              <img alt="notes" src={notesIcon} />
              <div className="single-booking__summary-item-wrapper">
                <div
                  className="single-booking__summary-item-title"
                  data-qa="single-booking-summary-user-comments"
                  data-testid="single-booking-summary-user-comments"
                >
                  {userComments || t('single-booking_no-user-comments')}
                </div>
              </div>
              <hr />
            </div>
            <div className="single-booking__summary-item column is-12">
              <img alt="stairs" src={stairsIcon} />
              <div className="single-booking__summary-item-wrapper">
                <div className="single-booking__summary-item-title">
                  <span>{t(stairs)}</span>
                </div>
              </div>
              <hr />
            </div>
            {booking.parking && (
              <div className="single-booking__summary-item column is-12">
                <img alt="parking" src={carIcon} />
                <div className="single-booking__summary-item-wrapper">
                  <div className="single-booking__summary-item-title">booking.parking</div>
                </div>
                <hr />
              </div>
            )}
            {(booking.cats || booking.dogs) && (
              <div className="single-booking__summary-item column is-12">
                <div className="single-booking__summary-item-title text-right">
                  {booking.cats && <img alt="cat" src={catIcon} />}
                  {booking.dogs && <img alt="dog" src={dogIcon} />}
                </div>
              </div>
            )}
            <hr />
          </>
        )}
      </div>
    );
  }
}

export default withTranslation('single-booking')(SingleBookingSummary);
