import React, { FC } from 'react';
import { withUser } from '../hocs/withUser';
import { useStore } from '../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../server/i18n';
import appConfig from '../helpers/config';
import Link from './Link';
import CountrySelect from './CountrySelect';

import './header.scss';

const {
  publicRuntimeConfig: { SALESFORCE_FAQ_BASE_URL },
} = appConfig;

interface HeaderProps extends WithTranslationType {
  currentRoute?: string;
  session: {
    isLoggedIn: boolean;
  };
  showLanguageSelect?: boolean;
}

interface UserControlsProps extends Partial<WithTranslationType> {
  currentRoute?: string;
  session: {
    isLoggedIn: boolean;
  };
}

const UserControls: FC<UserControlsProps> = (props: UserControlsProps) => {
  const { i18nUtils } = useStore();

  const { currentRoute, session, t } = props;

  switch (currentRoute) {
    case '/login':
      return (
        <Link id="js-headerApplyNowLink" route="/signup">
          <button
            className="header__link button button--blue"
            data-qa="apply-now-button"
            data-testid="js-apply-now-button"
            id="js-log-button"
            type="button"
          >
            <span>{t('header_apply')}</span>
          </button>
        </Link>
      );
    case '/forgot-password':
      return (
        <>
          <Link
            className="header__link button button--clear"
            id="js-headerLoginButton"
            prefetch={false}
            route="/login"
          >
            {t('header_login-button')}
          </Link>
          <Link id="js-headerApplyNowLink" route="/signup">
            <button
              className="header__link button button--blue"
              data-qa="apply-now-button"
              data-testid="js-apply-now-button"
              id="js-log-button"
              type="button"
            >
              <span>{t('header_apply')}</span>
            </button>
          </Link>
        </>
      );
    default:
      return (
        <>
          {i18nUtils.umRegion === 'UK' ? (
            <a
              className="header__faq-link"
              href={SALESFORCE_FAQ_BASE_URL}
              id="js-headerFaqLink"
              rel="noopener noreferrer"
              target="_blank"
            >
              {t('header_faq-link')}
            </a>
          ) : (
            <Link className="header__faq-link" id="js-headerFaqLink" prefetch={false} route="/faq">
              {t('header_faq-link')}
            </Link>
          )}
          {session.isLoggedIn ? (
            <Link
              prefetch={false}
              route="/logout"
              className="button button--clear"
              id="js-headerLogoutButton"
            >
              {t('header_logout-button')}
            </Link>
          ) : (
            <Link
              className="button button--clear"
              id="js-headerLoginButton"
              prefetch={false}
              route="/login"
            >
              {t('header_login-button')}
            </Link>
          )}
        </>
      );
  }
};

const Header: FC<HeaderProps> = (props: HeaderProps) => {
  const { currentRoute, i18n, session, showLanguageSelect, t } = props;

  return (
    <div className="header columns columns--no-max is-vcentered is-mobile">
      <div className="header__logo-wrapper is-vcentered columns is-mobile is-one-fourth">
        <div className="columns">
          <Link className="column" route="/">
            <img
              alt={t('header_logo-alt')}
              className="header__logo"
              src="/static/images/urban-logo-hero.svg"
            />
          </Link>
        </div>
        <div className="header__locale column">
          {showLanguageSelect && <CountrySelect i18n={i18n} t={t} />}
        </div>
      </div>
      <div className="header__user-controls-wrapper is-hidden-mobile column">
        <UserControls currentRoute={currentRoute} t={t} session={session} />
      </div>
      <div className="header__user-controls-wrapper is-hidden-desktop is-hidden-tablet column is-one-fifth-desktop">
        <Link prefetch={false} route="/login">
          <img alt="account" src="/static/images/icons/account.svg" />
        </Link>
      </div>
    </div>
  );
};

export default withTranslation('header')(withUser(Header));
