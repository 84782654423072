import React, { Fragment } from 'react';
import { observer } from 'mobx-react';

import { TreatmentToBothGendersEnum } from '../../helpers/enums';

import RadioButton from '../../components/RadioButton';
import { withTranslation, WithTranslationType } from '../../server/i18n';

interface Props extends WithTranslationType {
  errors: {
    treatmentToBothGenders: boolean;
  };
  setTreatmentToBothGenders: Function;
  treatmentToBothGenders: string;
}

const ProvideTreatmentTo = observer(
  ({ errors, setTreatmentToBothGenders, t, treatmentToBothGenders }: Props) => (
    <Fragment>
      <div className="column is-12">
        <p className="is-hidden-tablet">{t('signup_treatment-to-both-genders-label')}</p>
        <div className="row-mobile pull-right">
          <RadioButton
            checked={treatmentToBothGenders === TreatmentToBothGendersEnum.YES}
            className="half-width-mobile"
            id="js-treatment-to-both-genders-yes"
            name="treatmentToBothGenders"
            onClick={setTreatmentToBothGenders}
            value={TreatmentToBothGendersEnum.YES}
          >
            {t('signup_treatment-to-both-genders-yes')}
          </RadioButton>
          <RadioButton
            checked={treatmentToBothGenders === TreatmentToBothGendersEnum.NO}
            className="half-width-mobile"
            id="js-treatment-to-both-genders-no"
            name="treatmentToBothGenders"
            onClick={setTreatmentToBothGenders}
            value={TreatmentToBothGendersEnum.NO}
          >
            {t('signup_treatment-to-both-genders-no')}
          </RadioButton>
        </div>
        <p className="is-hidden-mobile signup-form__pull-left-question">
          {t('signup_treatment-to-both-genders-label')}
        </p>
        {errors.treatmentToBothGenders && (
          <div
            className="signup__error"
            data-qa="treatment-to-both-genders-error"
            id="js-treatment-to-both-genders-error"
          >
            <span>
              {treatmentToBothGenders
                ? t('signup_treatment-to-both-genders-required')
                : t('signup_required-field-error')}
            </span>
          </div>
        )}
      </div>
      <hr />
    </Fragment>
  ),
);

export default withTranslation('signup')(ProvideTreatmentTo);
