import React, { FC } from 'react';
import { withTranslation, WithTranslationType } from '../../server/i18n';

interface Props extends WithTranslationType {
  item: {
    type: string;
    speciality: any;
  };
}

const BookingItemType: FC<Props> = ({ item, t }: Props) => {
  switch (item.type) {
    case 'product':
      return <div>{item.speciality.name}</div>;
    case 'addition':
      return <div>{t('single-booking_item-type-addition')}</div>;
    case 'tip':
      return <div>{t('single-booking_item-type-tip')}</div>;
    default:
      return <div>{t('single-booking_item-type-unknown')}</div>;
  }
};

export default withTranslation('single-booking')(BookingItemType);
