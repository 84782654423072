import React, { Fragment, KeyboardEvent } from 'react';
import Formsy from 'formsy-react';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'next/router';
import { action, autorun, IReactionDisposer, observable, reaction } from 'mobx';
import { Element, scroller } from 'react-scroll';

import { ISplitTreatmentsChildProps } from '@splitsoftware/splitio-react/types/types';

import autobind from 'autobind-decorator';
import { Instance } from 'mobx-state-tree';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { ISignupFormData, ISignupFormErrors } from '../../../types/signup';
import { QualificationEnum } from '../../helpers/enums';
import { Session } from '../../stores/session';
import { Tracker } from '../../stores/tracker';
import { CitiesList } from '../../stores/cities';
import { Config } from '../../stores/config';
import { I18nUtils } from '../../stores/i18nUtils';
import { Selection } from '../../stores/selection';

import City from './SignupCity';
import PersonalDetails from './SignupPersonalDetails';
import Qualification from './SignupQualification';
import SignupPersonalSubmitButton from './SignupPersonalSubmitButton';
import TermsAndConditions from './SignupTermsAndConditions';

import {
  PERSONAL_DETAILS_ERROR,
  SCROLL_DELAY,
  SCROLL_DURATION,
  SCROLL_OFFSET,
  SERVER_ERROR_CODE,
  SERVER_VALIDATION_ERROR,
  TERMS_AND_CONDITIONS_ERROR,
} from '../../helpers/constants';
import { TRACKER_CATEGORY_CREATE_ACCOUNT } from '../../helpers/trackerConstants';
import { withRedirectToBookings } from '../../hocs/withRedirectToBookings';

const ENTER_KEY_CODE = 13;

interface ISignupProps extends WithTranslationType {
  cities: Instance<typeof CitiesList>;
  config: Instance<typeof Config>;
  i18nUtils: Instance<typeof I18nUtils>;
  router: {
    asPath: string;
    push: (route: string) => void;
    query: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  };
  selection: Instance<typeof Selection>;
  session: Instance<typeof Session>;
  tracker: Instance<typeof Tracker>;
}

@(withRouter as any) // eslint-disable-line @typescript-eslint/no-explicit-any
@inject(({ store: { cities, config, i18nUtils, selection, session, tracker } }) => ({
  cities,
  config,
  i18nUtils,
  selection,
  session,
  tracker,
}))
@observer
export class SignupForm extends React.Component<ISignupProps> {
  qualifiedFaqArticle: string;
  listeners: IReactionDisposer[];
  showVerticalsSignup: boolean;

  @observable errors: ISignupFormErrors;
  @observable formData: ISignupFormData;
  @observable loading = false;
  @observable submitted = false;

  constructor(props: ISignupProps) {
    super(props);
    const { config, i18nUtils } = props;

    this.formData = {
      city: undefined,
      lastAcceptedMarketingCommunicationsAt: false,
      personalDetails: {
        email: {
          error: undefined,
          value: undefined,
        },
        firstName: {
          error: undefined,
          value: undefined,
        },
        lastName: {
          error: undefined,
          value: undefined,
        },
        legalName: {
          error: undefined,
          value: undefined,
        },
        mobileNumber: {
          error: undefined,
          value: undefined,
        },
        password: {
          error: undefined,
          value: undefined,
        },
        referralCode: {
          error: undefined,
          value: undefined,
        },
      },
      qualification: undefined,
      termsAndConditions: false,
    };

    this.errors = {
      personalDetails: false,
      serverValidation: false,
      serverErrorCode: undefined,
      termsAndConditions: false,
    };

    this.qualifiedFaqArticle = config.getValue(
      'hero.portal.signup.qualified-faq-article-stub',
      i18nUtils.umRegion,
    );
  }

  componentDidMount(): void {
    const { handlePersonalDetailsChange, props, setCity } = this;
    const {
      router,
      selection,
    } = props;

    if (selection) {
      selection.loadQuery(router.query);

      if (selection.city) {
        setCity(selection.city);
      }

      if (selection.email) {
        handlePersonalDetailsChange('email')({
          currentTarget: {
            value: selection.email,
          },
        });
      }

      if (selection.rf) {
        handlePersonalDetailsChange('referralCode')({
          currentTarget: {
            value: selection.rf,
          },
        });
      }
    }

    this.listeners = [
      autorun(() => {
        const { session } = this.props;

        if (router.query && router.query.rf) {
          session.setReferrerCookie(router.query.rf as string);

          return;
        }

        if (
          session &&
          session.getReferrerCookie &&
          typeof session.getReferrerCookie === 'function'
        ) {
          handlePersonalDetailsChange('referralCode')({
            currentTarget: {
              value: session.getReferrerCookie(),
            },
          });
        }
      }),
      reaction(
        () => this.formData.city,
        city => {
          const { tracker } = this.props;

          tracker.track('City for therapist selected', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            city,
          });
        },
      ),
      reaction(
        () => this.formData.lastAcceptedMarketingCommunicationsAt,
        lastAcceptedMarketingCommunicationsAt => {
          const { tracker } = this.props;

          tracker.track('Updated marketing communications', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            lastAcceptedMarketingCommunicationsAt,
          });
        },
      ),
      reaction(
        () => this.formData.qualification,
        qualification => {
          const { tracker } = this.props;
          const { city } = this.formData;

          if (qualification === QualificationEnum.CERTIFIED) {
            tracker.track('Pressed qualified therapist', {
              category: TRACKER_CATEGORY_CREATE_ACCOUNT,
              city,
            });
            return;
          }

          tracker.track(
            qualification === QualificationEnum.STUDENT
              ? 'Clicked still studying'
              : 'Clicked planning to study',
            {
              category: TRACKER_CATEGORY_CREATE_ACCOUNT,
              city,
            },
          );
        },
      ),
      reaction(
        () => this.formData.termsAndConditions,
        termsAndConditions => {
          const { tracker } = this.props;

          tracker.track('Updated terms and conditions', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            termsAndConditions,
          });
        },
      ),
    ];
  }

  componentWillUnmount(): void {
    while (this.listeners.length) {
      this.listeners.pop()();
    }
  }

  // client side validation
  @autobind
  @action('onInvalidSubmit - SignupForm')
  onInvalidSubmit(): void {
    this.validateForm();
  }

  /* eslint-disable class-methods-use-this */
  @autobind
  onKeyPress(event: KeyboardEvent): void {
    if (event.which === ENTER_KEY_CODE) {
      event.preventDefault();
    }
  }
  /* eslint-enable class-methods-use-this */

  @autobind
  onValid(): void {
    if (this.submitted) {
      this.validateForm();
    }
  }

  // server side validation
  @autobind
  @action('onValidSubmit - SignupForm')
  onValidSubmit(): void {
    this.loading = true;
    if (this.validateForm()) {
      const {
        i18nUtils,
        router,
        router: { query },
        session,
        tracker,
      } = this.props;

      session
        .signup(this.formData, query)
        .then(data => {
          this.loading = false;

          if (data.status === 'ERROR') {
            this.setError(SERVER_VALIDATION_ERROR, true);
            this.setError(SERVER_ERROR_CODE, data.code);

            tracker.track('Submit application error', {
              category: TRACKER_CATEGORY_CREATE_ACCOUNT,
              message: data && data.message,
            });
          } else {
            session.login(data);

            router.push(`/${i18nUtils.locale}/submit-application`);
          }
        })
        .catch(error => {
          tracker.track('Error occurred while submitting application form', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            error,
          });

          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  @autobind
  @action('setCity - SignupForm')
  setCity(city: string): void {
    const { config, i18nUtils } = this.props;
    this.formData.city = city;

    const verticalsCitiesList = config.getValue(
      'hero.portal.signup.verticals-cities-list',
      i18nUtils.umRegion,
    );

    this.showVerticalsSignup = verticalsCitiesList.indexOf(city) > -1;
  }

  @autobind
  @action('setError - SignupForm')
  setError(key: string, value: boolean | string): void {
    this.errors[key] = value;
  }

  @autobind
  @action('setMarketingComms - SignupForm')
  setMarketingComms(event: React.ChangeEvent<HTMLInputElement>): void {
    this.formData.lastAcceptedMarketingCommunicationsAt = event.target.checked;
  }

  @autobind
  @action('setQualification - SignupForm')
  setQualification(qualification: string): void {
    this.formData.qualification = qualification;
  }

  @autobind
  @action('setTermsAndConditions - SignupForm')
  setTermsAndConditions(event: React.ChangeEvent<HTMLInputElement>): void {
    this.formData.termsAndConditions = event.target.checked;
    this.setError(TERMS_AND_CONDITIONS_ERROR, !event.target.checked);
  }

  @autobind
  @action('handlePersonalDetailsChange - SignupForm')
  handlePersonalDetailsChange(fieldName: string): Function {
    return (event: React.ChangeEvent<HTMLInputElement>, hasError = false): void => {
      const { personalDetails } = this.formData;

      if (event) {
        personalDetails[fieldName].value = event.currentTarget.value;
      }

      personalDetails[fieldName].error = hasError;

      if (fieldName === 'email') {
        this.setError(SERVER_ERROR_CODE, false);
        this.setError(SERVER_VALIDATION_ERROR, false);
      }
    };
  }

  /* eslint-disable class-methods-use-this */
  @action('scrollToElem - SignupForm')
  scrollToElem(name: string): void {
    scroller.scrollTo(name, {
      duration: SCROLL_DURATION,
      delay: SCROLL_DELAY,
      offset: SCROLL_OFFSET,
      smooth: true,
    });
  }
  /* eslint-enable class-methods-use-this */

  @action('validateForm - SignupForm')
  validateForm(): boolean {
    const { i18nUtils } = this.props;
    const { scrollToElem, setError } = this;
    const { personalDetails, termsAndConditions } = this.formData;
    const { email, firstName, lastName, legalName, mobileNumber, password } = personalDetails;

    this.submitted = true;

    // FR uses 2 different fields for name (firstName and lastName), UK uses a single field
    const personalFields =
      i18nUtils.umRegion === 'FR'
        ? [email, firstName, lastName, mobileNumber, password]
        : [email, legalName, mobileNumber, password];

    const errors = new Map<string, boolean>([
      [
        PERSONAL_DETAILS_ERROR,
        Object.keys(personalDetails).some(key => personalDetails[key].error) ||
          personalFields.some(i => typeof i.value === 'undefined'),
      ],
      [TERMS_AND_CONDITIONS_ERROR, !termsAndConditions],
    ]);

    errors.forEach((value, key) => setError(key, value));

    /* eslint-disable no-restricted-syntax */
    for (const [key, value] of errors) {
      if (value) {
        scrollToElem(key);
        return false;
      }
    }
    /* eslint-enable no-restricted-syntax */

    return true;
  }

  render(): JSX.Element {
    const {
      errors,
      formData,
      handlePersonalDetailsChange,
      loading,
      onInvalidSubmit,
      onKeyPress,
      onValid,
      onValidSubmit,
      qualifiedFaqArticle,
      setCity,
      setMarketingComms,
      setQualification,
      setTermsAndConditions,
    } = this;
    const {
      city,
      lastAcceptedMarketingCommunicationsAt,
      personalDetails,
      qualification,
      termsAndConditions,
    } = formData;
    const { config, i18nUtils, t } = this.props;

    return (
      <Formsy
        onInvalidSubmit={onInvalidSubmit}
        onKeyPress={onKeyPress}
        onValid={onValid}
        onValidSubmit={onValidSubmit}
        preventExternalInvalidation
      >
        <City city={city} setCity={setCity} t={t} />
        {city && (
          <Fragment>
            <Qualification
              articleId={qualifiedFaqArticle}
              qualification={qualification}
              setQualification={setQualification}
            />
            {qualification === QualificationEnum.CERTIFIED && (
              <Fragment>
                <Element name={PERSONAL_DETAILS_ERROR}>
                  <PersonalDetails
                    config={config}
                    i18nUtils={i18nUtils}
                    onChange={handlePersonalDetailsChange}
                    personalDetails={personalDetails}
                    t={t}
                  />
                </Element>
                <Element name={TERMS_AND_CONDITIONS_ERROR}>
                  <TermsAndConditions
                    data={{
                      lastAcceptedMarketingCommunicationsAt,
                      termsAndConditions,
                    }}
                    errors={errors}
                    setMarketingComms={setMarketingComms}
                    setTermsAndConditions={setTermsAndConditions}
                    t={t}
                  />
                </Element>
                <SignupPersonalSubmitButton
                  errors={errors}
                  loading={loading}
                  scrollTo={this.scrollToElem}
                  t={t}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </Formsy>
    );
  }
}

export default withTranslation('signup')(withRedirectToBookings(SignupForm));
