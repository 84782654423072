import React, { FC } from 'react';

import './application-screen-title.scss';

const ApplicationScreenSubtitle: FC<{ alignCenter?: boolean }> = ({
  alignCenter = false,
  children,
}) => {
  return (
    <h2
      className={`application-screen-title__subtitle ${alignCenter &&
        'application-screen-title__subtitle--centered'}`}
    >
      {children}
    </h2>
  );
};

export default ApplicationScreenSubtitle;
