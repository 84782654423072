import React, { FC, ReactElement } from 'react';

interface Props {
  checked: boolean;
  children: ReactElement | string;
  className?: string;
  id: string;
  name: string;
  onChange: (event: React.ChangeEvent) => void;
  tintedCheckbox?: boolean;
}

const Checkbox: FC<Props> = ({
  checked,
  children,
  className = '',
  id,
  name,
  onChange,
  tintedCheckbox,
}) => {
  return (
    <span className={`checkbox ${className}`}>
      <div className={tintedCheckbox ? 'tinted-checkbox' : 'green-checkbox'}>
        <input
          aria-label={name}
          checked={checked}
          className="form-control"
          data-qa={`${name}-input`}
          data-testid={id}
          id={id}
          name={name}
          onChange={onChange}
          type="checkbox"
        />

        <label
          data-qa={`${name}-label`}
          data-testid={`${id}-label`}
          htmlFor={id}
          id={`${id}-label`}
        >
          <span>{children}</span>
        </label>
      </div>
    </span>
  );
};

Checkbox.defaultProps = {
  tintedCheckbox: false,
};

export default Checkbox;
