import React, { useState } from 'react';

import { useStore } from '../../contexts/storeContext';
import { Trans, withTranslation, WithTranslationType } from '../../server/i18n';
import FAQPanel from '../../components/panels/FAQPanel';
import RadioButton from '../../components/RadioButton';
import SidePanel from '../../components/panels/SidePanel';
import { QualificationEnum } from '../../helpers/enums';
import { TRACKER_CATEGORY_CREATE_ACCOUNT } from '../../helpers/trackerConstants';

interface Props extends WithTranslationType {
  articleId: string;
  qualification: string;
  setQualification: Function;
}

export const Qualification = ({
  articleId,
  qualification,
  setQualification,
  t,
}: Props): JSX.Element => {
  const [showPanel, setShowPanel] = useState(false);
  const store = useStore();
  const { tracker } = store;

  if (
    qualification === QualificationEnum.STUDENT ||
    qualification === QualificationEnum.NOT_CERTIFIED
  ) {
    window.location.assign('/education');
  }

  const onClickSeeQualifications = (): void => {
    setShowPanel(true);
    tracker.track('Clicked see qualifications', {
      category: TRACKER_CATEGORY_CREATE_ACCOUNT,
    });
  };

  return (
    <div
      className="signup-form__qualification-section"
      data-qa="qualification-section"
      data-testid="qualifaction-section"
      id="qualifaction-section"
    >
      <SidePanel setShowPanel={setShowPanel} show={showPanel}>
        <FAQPanel articleId={articleId} />
      </SidePanel>
      <hr />
      <p
        data-qa="qualification-question"
        data-testid="qualification-question"
        id="qualification-question"
      >
        <Trans t={t} i18nKey="signup_qualified-therapist-question">
          Are you a qualified practitioner?
          <span onClick={onClickSeeQualifications}>see qualifications</span>
        </Trans>
      </p>
      <RadioButton
        checked={qualification === QualificationEnum.CERTIFIED}
        className="full-width-mobile"
        id="js-certified"
        name="certified"
        onClick={setQualification}
        value={QualificationEnum.CERTIFIED}
      >
        {t('signup_qualification-qualified')}
      </RadioButton>
      <div className="row-mobile">
        <RadioButton
          checked={qualification === QualificationEnum.STUDENT}
          className="half-width-mobile"
          id="js-student"
          name="certified"
          onClick={setQualification}
          value={QualificationEnum.STUDENT}
        >
          {t('signup_qualification-still-training')}
        </RadioButton>
        <RadioButton
          checked={qualification === QualificationEnum.NOT_CERTIFIED}
          className="half-width-mobile"
          id="js-not-certified"
          name="certified"
          onClick={setQualification}
          value={QualificationEnum.NOT_CERTIFIED}
        >
          {t('signup_qualification-not-trained')}
        </RadioButton>
      </div>
      <hr />
    </div>
  );
};

export default withTranslation('signup')(Qualification);
