import React, { FC } from 'react';
import { observer, inject } from 'mobx-react';

import { withRouter, useRouter } from 'next/router';
import getConfig from 'next/config';

import Head from 'next/head';
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import { useStore } from '../contexts/storeContext';
import * as routesInfo from '../server/routes';
import { withTranslation, WithTranslationType } from '../server/i18n';

const { publicRuntimeConfig } = getConfig();
const { DEFAULT_LOCALES, HTTP_HOST, SUPPORTED_LOCALES } = publicRuntimeConfig;

interface HeadTagsProps {
  i18nUtils?: {
    locale: string;
  };
  router?: {
    asPath: string;
    query: {
      supportedLocales: string[];
    };
  };
}

const HeadTags: FC<WithTranslationType> = observer(
  ({ t }): JSX.Element => {
    const { i18nUtils } = useStore();
    const router = useRouter();

    let canonical = '';

    let alternateLinks = [];

    if (i18nUtils.locale && canonical.indexOf(i18nUtils.locale) === -1) {
      canonical = `/${i18nUtils.locale}${canonical}`;
    }

    if (i18nUtils.locale && canonical.indexOf(i18nUtils.locale) !== -1) {
      const supportedLocales = router.query && router.query.supportedLocales;

      if (supportedLocales) {
        // On a server side rendered page we get the page's supportedLocales list from the router query
        alternateLinks = supportedLocales.map(locale => ({
          href: canonical.replace(i18nUtils.locale, locale.toLowerCase()),
          hrefLang: locale.toLowerCase(),
        }));
      } else {
        // If we're navigating inside the next.js app, we need to get the supportedLocales from the
        // routes list
        const routeDetails = routesInfo.match(router.asPath);

        if (routeDetails && routeDetails.route && routeDetails.route.keys) {
          const [clientLocales] = routeDetails.route.keys.filter(route => route.name === 'locale');

          const newLocales =
            clientLocales && clientLocales.pattern ? clientLocales.pattern.split('|') : null;

          if (newLocales) {
            alternateLinks = newLocales.map(locale => ({
              href: canonical.replace(i18nUtils.locale, locale.toLowerCase()),
              hrefLang: locale.toLowerCase(),
            }));
          }
        }
      }

      const defaults = DEFAULT_LOCALES.map(locale => locale.toLowerCase());

      // We also need to include default URL's for language only so en -> en-gb, fr -> fr-fr etc
      const defaultLocales = SUPPORTED_LOCALES.filter(
        locale => defaults.indexOf(locale.toLowerCase()) !== -1,
      ).map(item => ({
        href: canonical.replace(i18nUtils.locale, item.toLowerCase()),
        hrefLang: item.split('-')[0],
      }));

      alternateLinks = alternateLinks.concat(defaultLocales);

      alternateLinks.forEach(alt => {
        if (alt.href && !/http/.test(alt.href)) {
          alt.href = `https://${HTTP_HOST}${alt.href}`;
        }
      });
    } else {
      alternateLinks = [];
    }

    return (
      <>
        <Head key="head-tags-head-1">
          <link crossOrigin="anonymous" href="https://cdn.segment.com" rel="preconnect" />
          <link crossOrigin="anonymous" href="https://api.segment.io" rel="preconnect" />
          <meta
            content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
            name="viewport"
          />
          <link href={`https://${HTTP_HOST}${canonical}`} rel="canonical" />
          {alternateLinks &&
            alternateLinks.map(hrefLink => (
              <link
                href={hrefLink.href}
                hrefLang={hrefLink.hrefLang}
                key={`${hrefLink.href}-${hrefLink.hrefLang}`}
                rel="alternate"
              />
            ))}
          <meta content="IE=edge" httpEquiv="X-UA-Compatible" />
          <meta content="London" property="business:contact_data:locality" />
          <meta content="United Kingdom" property="business:contact_data:country_name" />
        </Head>
        <DefaultSeo
          title={t('home_meta-title')}
          titleTemplate="%s | Urban"
          description={t('home_meta-description')}
          openGraph={{
            type: 'website',
            site_name: 'Urban',
            url: t('home_og-url'),
            title: t('home_og-title'),
            description: t('home_og-description'),
            images: [
              {
                alt: t('home_og-alt-text'),
                url:
                  'https://res.cloudinary.com/huxr6hrje/image/upload/c_scale,w_192/v1517913336/windows-tile-310x310_tiifpp.png',
              },
            ],
          }}
          twitter={{
            handle: '@urbanappuk',
            site: '@urbanappuk',
            cardType: 'summary',
          }}
        />
        <SocialProfileJsonLd
          key="head-tags-social-profile"
          name="Urban"
          sameAs={[
            'https://www.linkedin.com/company/5281522',
            'https://uk.trustpilot.com/review/urban.co',
            'https://www.youtube.com/channel/UCVHIGlxIPNYgz1O__E3XfsA',
            'https://www.facebook.com/urbanappuk',
            'https://www.instagram.com/urbanapp',
            'https://twitter.com/Urbanappuk',
          ]}
          type="Organization"
          url="https://urban.co/en-gb/"
        />
      </>
    );
  },
);

export default withTranslation('home')(HeadTags);
