export const GENDER_ERROR = 'gender';
export const LEARN_MORE_SECTION = 'LEARN_MORE_SECTION';
export const PERSONAL_DETAILS_ERROR = 'personalDetails';
export const PREFERRED_HOURS_ERROR = 'preferredHours';
export const REFERRER_COOKIE = 'referrer';
export const SCROLL_DELAY = 100;
export const SCROLL_DURATION = 1000;
export const SCROLL_OFFSET = -100;
export const SELECTED_TREATMENTS_ERROR = 'selectedTreatments';
export const SERVER_ERROR_CODE = 'serverErrorCode';
export const SERVER_VALIDATION_ERROR = 'serverValidation';
export const SUPPORTED_LANG = ['en-gb', 'fr-fr'];
export const TERMS_AND_CONDITIONS_ERROR = 'termsAndConditions';
export const TRANSPORT_TYPE_ERROR = 'transportType';
export const TREATMENTS_TO_BOTH_GENDERS_ERROR = 'treatmentToBothGenders';
export const USER_SESSION_COOKIE = 'userSession';
export const LEGACY_LANGUAGE_COOKIE = 'language';
