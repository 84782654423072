import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { dateFormat } from '../../helpers';

import { withTranslation, WithTranslationType } from '../../server/i18n';
import { useStore } from '../../contexts/storeContext';

import { IJoinCallUrl } from '../../stores/SingleBookingItem';

import './video-call-row.scss';

interface IVideoCallRowProps extends WithTranslationType {
  reservation: IJoinCallUrl;
  isLast?: boolean;
  timezone: string;
}

const VideoCallRow: FC<IVideoCallRowProps> = observer(({ isLast, t, reservation, timezone }) => {
  const { tracker } = useStore();

  const onClickJoinCall = (): void => {
    const {joinCallUrl} = reservation;

    tracker.track('Start video call clicked', {
      category: 'video call list',
    });

    // Open new tab / window
    window.open(joinCallUrl);
  }

  //TODO move to booking store views
  const reservationTimeFormatted = useMemo(() => {
    return `${dateFormat(reservation.startsAt, 'HH:mm', timezone)} - ${dateFormat(reservation.endsAt, 'HH:mm', timezone)}`;
  }, [reservation.startsAt, reservation.endsAt]);

  return (
    <div className={cn('video-call-row', isLast && 'video-call-row--last')}>
      <div
        className="video-call-row__column"
        data-qa="video-call-row-time"
        data-testid="video-call-row-time"
      >
        {reservationTimeFormatted}
      </div>
      <div className="video-call-row__column">{reservation.serviceName}</div>
      <div className="video-call-row__column video-call-row__column--small video-call-row__column--align-right">
        <button
          className="video-call-row__button button button--blue"
          data-qa="video-call-join-button"
          data-testid="video-call-join-button"
          onClick={onClickJoinCall}
          type="button"
        >
          {t('video-call-list_join-call-button')}
        </button>
      </div>
    </div>
  );
});

export default withTranslation('video-call-list')(VideoCallRow);
