import React from 'react';
import { inject, observer } from 'mobx-react';
import { parseCookies } from 'nookies';
import Router, { withRouter } from 'next/router';

interface WithUserProps {
  router: any;
  session: {
    getUser: () => Promise<any>;
    isLoggedIn: boolean;
    setToken: (userSessionCookie: string) => void;
    setUTMCookies: () => void;
    user: {
      id: number;
      name: string;
    };
  };
  tracker: {
    identify: any;
  };
}

export const withUser = (Component: any) => {
  @inject(({ store: { session, tracker } }) => ({
    session,
    tracker,
  }))
  @observer
  @(withRouter as any)
  class WithUser extends React.Component<WithUserProps, null> {
    static async getInitialProps({ lng, req }, { articles, cities, cmsContent }) {
      // Check if Page has a `getInitialProps`; if so, call it.
      const pageProps =
        Component.getInitialProps &&
        (await Component.getInitialProps({ lng, req }, { articles, cities, cmsContent }));
      // Return props.
      return { ...pageProps };
    }

    constructor(props, ctx) {
      super(props);
      const {
        router: { asPath },
        session,
      } = this.props;

      // Get the domain cookies
      const cookies = parseCookies(ctx);
      let userSessionCookie;

      if (typeof window !== 'undefined') {
        session.setUTMCookies();

        if (cookies) {
          userSessionCookie = cookies.userSession;
        }

        // Check user token is valid
        if (userSessionCookie && userSessionCookie !== 'null') {
          session.setToken(userSessionCookie);
        } else {
          // TODO - this replaces some check that shouldn't be here temporarily - will be removed soon
          const authedRoutes = ['/bookings', '/diary', '/service-areas'];
          if (
            authedRoutes.some(route => {
              return asPath.indexOf(route) > -1;
            })
          ) {
            Router.push('/');
          }
        }
      }
    }

    render(): JSX.Element {
      return <Component {...this.props} />;
    }
  }

  return WithUser;
};
