import React, { ChangeEvent, Fragment } from 'react';

import { Trans } from '../../server/i18n';
import Checkbox from '../../components/Checkbox';

interface Props {
  data: {
    termsAndConditions: boolean;
    lastAcceptedMarketingCommunicationsAt: boolean;
  };
  errors: {
    termsAndConditions: boolean;
  };
  setMarketingComms: (event: ChangeEvent) => void;
  setTermsAndConditions: (event: ChangeEvent) => void;
  t: (param: string) => string;
}

export const TermsAndConditions = ({
  data,
  errors,
  setMarketingComms,
  setTermsAndConditions,
  t,
}: Props): JSX.Element => (
  <Fragment>
    <Checkbox
      checked={data.termsAndConditions}
      id="js-terms-and-conditions"
      name="termsAndConditions"
      onChange={setTermsAndConditions}
      tintedCheckbox
    >
      <Trans t={t} i18nKey="signup_accept-terms-and-conditions-label">
        I accept the&nbsp;
        <a
          className="anchor"
          href={t('signup_terms-and-conditions-link')}
          id="terms-and-conditions"
          rel="noopener noreferrer" target="_blank"
        >
          terms and conditions
        </a>

&amp;&nbsp;
        <a
          className="anchor"
          href={t('signup_privacy-policy-link')}
          id="privacy-policy" rel="noopener noreferrer"
          target="_blank"
        >
          privacy policy
        </a>
      </Trans>
    </Checkbox>
    {errors.termsAndConditions && (
      <div
        className="signup__error"
        data-qa="terms-and-conditions-error"
        data-testid="js-terms-and-conditions-error"
        id="js-terms-and-conditions-error"
      >
        <span>{t('signup_accept-terms-and-conditions-error')}</span>
      </div>
    )}

    <Checkbox
      checked={data.lastAcceptedMarketingCommunicationsAt}
      id="js-marketing-comms"
      name="marketingComms"
      onChange={setMarketingComms}
      tintedCheckbox
    >
      {t('signup_marketing-comms-label')}
    </Checkbox>
  </Fragment>
);

export default TermsAndConditions;
