import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { useStore } from '../../contexts/storeContext';

import VideoCallRow from './VideoCallRow';

import './video-call-list.scss';


const VideoCallList: FC<WithTranslationType> = observer(({ t }) => {
  const { singleBooking: {booking} } = useStore();

  if (booking.joinCallUrls && booking.joinCallUrls.length) {
    return (
      <section className="video-call-list">
        <header className="video-call-list__header">
          <h3 className="video-call-list__heading">{t('video-call-list_title')}</h3>
          <p>{t('video-call-list_subtitle')}</p>
        </header>
        <div className="video-call-list__table">
          {booking.orderedJoinCallUrls.map((reservation, index) => (
            <VideoCallRow
              reservation={reservation}
              key={reservation.key}
              isLast={index === booking.orderedJoinCallUrls.length - 1}
              timezone={booking.bookingTimezone}
            />
          ))}
        </div>
      </section>
    );
  }

  return null;
});

export default withTranslation('video-call-list')(VideoCallList);
