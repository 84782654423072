import { format, utcToZonedTime } from 'date-fns-tz';

export const truncateString = (str: string, num: number): string => {
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const truncateWords = (
  str: string,
  maxWords: number,
  separator = ',',
  replacement = '...',
): string => {
  const words = str.split(separator);
  if (words.length <= maxWords) {
    return str;
  }
  const slicedWords = words.slice(0, maxWords);

  return `${slicedWords.join(separator)}${replacement}`;
};

export const dateFormat = (date: string, chosenFormat: string, timezone?: string, useLocale?: string): string => {
  if (timezone && useLocale) {
    const chosenLocale = require(`date-fns/locale/${useLocale}`).default;

    const zonedDate = utcToZonedTime(date, timezone);

    return format(zonedDate, chosenFormat, { timeZone: timezone, locale: chosenLocale });
  }

  if (timezone) {
    const zonedDate = utcToZonedTime(date, timezone);

    return format(zonedDate, chosenFormat, { timeZone: timezone });
  }

  const chosenDate = new Date(date);

  return format(chosenDate, chosenFormat);
};

export const currency = (amount: string, currencyValue: string): string => {
  const chosenCurrency = currencyValue || '';

  return `${chosenCurrency}${amount}`;
};
