import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Element } from 'react-scroll';

import cn from 'classnames';
import { Trans } from '../../server/i18n';
import { ISignupFormErrors } from '../../../types/signup';

import Link from '../../components/Link';
import Spinner from '../../components/Spinner';

interface Props {
  errors: ISignupFormErrors;
  loading: boolean;
  scrollTo: Function;
  t: (param: string) => string;
}

@observer
class SignupPersonalSubmitButton extends Component<Props, null> {
  @computed get hasErrors(): boolean {
    const { errors } = this.props;
    return Object.values(errors).some(i => i);
  }

  render(): JSX.Element {
    const { hasErrors } = this;
    const { errors, loading, scrollTo, t } = this.props;
    let errorMessage: string | JSX.Element = t('signup_errors-in-form');

    if (errors.serverValidation) {
      scrollTo('errorMessageBox');

      if (errors.serverErrorCode === 'ACCOUNT_ALREADY_EXISTS_WITH_EMAIL_ERROR') {
        errorMessage = (
          <Trans i18nKey="signup_account-already-exists-with-email-error">
            An account already exists with this email address.
            <Link prefetch={false} route="/login">
              Login instead?
            </Link>
          </Trans>
        );
      } else if (errors.serverErrorCode === 'REFERRAL_CODE_INVALID_ERROR') {
        errorMessage = t('signup_referral-code-is-not-valid');
      } else if (errors.serverErrorCode === 'EMAIL_ADDRESS_NOT_VALID') {
        errorMessage = t('signup_invalid-email-error-message');
      } else {
        errorMessage = t('signup_error-occurred-while-submitting-application');
      }
    }

    return (
      <div className="signup__submit-btn-wrapper">
        <div className="text-center">
          <button
            className={cn('button button--submit-btn full-width-mobile', {
              disabled: loading || hasErrors,
              loading: loading && !hasErrors,
            })}
            data-qa="signup-submit-button"
            data-testid="js-signup-submit-button"
            formNoValidate
            id="js-signup-submit-button"
            type="submit"
          >
            {loading && !hasErrors ? (
              <Spinner />
            ) : (
              <span>{t('signup_submit-your-application')}</span>
            )}
          </button>
        </div>
        {hasErrors && (
          <Element name="errorMessageBox">
            <div
              className="signup__error-box"
              data-qa="error-box-message"
              data-testid="js-error-box-message"
            >
              {errorMessage}
            </div>
          </Element>
        )}
      </div>
    );
  }
}

export default SignupPersonalSubmitButton;
