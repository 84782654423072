import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';

import classie from '../lib/classie';

import './popup.scss';

interface PopupProps {
  children: ReactNode;
  doNotAddNoScroll?: boolean;
}

export default class Popup extends React.Component<PopupProps, any> {
  popup: { container: HTMLDivElement; subContainer: HTMLDivElement };

  componentDidMount() {
    this.popup = this.addPopup(undefined, this.props.doNotAddNoScroll);
    ReactDOM.unstable_renderSubtreeIntoContainer(
      this,
      this.props.children,
      this.popup.subContainer,
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.children !== this.props.children) {
      ReactDOM.unstable_renderSubtreeIntoContainer(
        this,
        nextProps.children,
        this.popup.subContainer,
      );
    }
  }

  shouldComponentUpdate() {
    return false;
  }

  componentWillUnmount() {
    this.removePopup(this.popup);
  }

  addPopup(children, doNotAddNoScroll = false) {
    const container = document.createElement('div');

    const subContainer = document.createElement('div');
    classie.addClass(container, 'popup-container');
    classie.addClass(subContainer, 'popup-subContainer');
    container.appendChild(subContainer);
    document.body.appendChild(container);

    if (children) {
      subContainer.appendChild(children);
    }

    if (!doNotAddNoScroll) {
      classie.addClass(document.body, 'no-scroll');
    }

    return { container, subContainer };
  }

  removePopup(popup) {
    if (classie.hasClass(document.body, 'no-scroll')) {
      classie.removeClass(document.body, 'no-scroll');
    }
    document.body.removeChild(popup.container);
  }

  render() {
    return null;
  }
}
