import React, { FC } from 'react';
import {observer} from 'mobx-react';
import { dateFormat } from '../../helpers';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { useStore } from '../../contexts/storeContext';

const SingleBookingNotes: FC<WithTranslationType> = observer(({ t }) => {
  const { singleBooking: {
    booking,
    isPastBooking,
  }} = useStore();

  if (!isPastBooking && booking.filteredNotesHistory.length) {
    return (
      <section className="column is-12-mobile single-booking__grey-bg single-booking__section">
        <div className="columns is-multiline is-mobile">
          <div className="column is-10-mobile is-offset-1-mobile is-5-tablet is-offset-1-tablet">
            <h1>{t('single-booking_notes-section-title')}</h1>
            {booking.filteredNotesHistory.map(note => (
              <div className="single-booking__booking-note" key={note.id}>
                <p className="single-booking__booking-note-therapist-name">{note.noteWorkerName}</p>
                <p className="single-booking__booking-note-booking-time">
                  {dateFormat(note.createdAt, 'dddd, MMMM Do YYYY')}
                </p>
                <p className="single-booking__booking-note-note">{note.heroNote}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  return null;
});

export default withTranslation('single-booking')(SingleBookingNotes);
