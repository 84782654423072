import React from 'react';

interface EnvSplitProps {
  browser?: Function;
  server?: Function;
}

class EnvSplit extends React.Component<EnvSplitProps, null> {
  isBrowser: boolean;

  componentDidMount() {
    if (typeof window !== 'undefined') {
      // only show after initial render so we don't get a dom mismatch error
      this.isBrowser = true;
      this.forceUpdate();
    }
  }

  render() {
    const { browser, server } = this.props;
    if (this.isBrowser) {
      return (browser && browser()) || null;
    }
    return (server && server()) || null;
  }
}

export default EnvSplit;

export function browserOnly(Component): any {
  return props => <EnvSplit browser={() => <Component {...props} />} />;
}

export function serverOnly(Component) {
  return props => <EnvSplit server={() => <Component {...props} />} />;
}

export function jsOnly(Component) {
  return props => (
    <div className="js-only">
      <Component {...props} />
    </div>
  );
}
