import React, { useState, FC } from 'react';
import Formsy from 'formsy-react';
import { observer } from 'mobx-react';
import { useStore } from '../../contexts/storeContext';

import { TRACKER_CATEGORY_SINGLE_BOOKING_DETAILS } from '../../helpers/trackerConstants';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import LoadingIndicator from './LoadingIndicator';

const BookingNoteForm: FC<WithTranslationType> = observer(({ t }) => {
  const {
    singleBooking: {
      booking,
      clearIsUpdateError,
      isPastBooking,
      isUpdating,
      isUpdateError,
      updateBooking,
    },
    tracker,
  } = useStore();
  const [note, setNote] = useState(booking.heroNote || '');
  const [isEditing, setIsEditing] = useState(!booking.heroNote);

  const onSubmit = (): void => {
    setIsEditing(false);
    updateBooking({ heroNote: note });
  };

  const onChange = (event): void => {
    const {value} = event.target;

    if (isUpdateError) {
      clearIsUpdateError();
    }

    setNote(value);
  };

  const handleStartEditing = (): void => {
    setIsEditing(true);

    tracker.track('Pressed Edit Booking Note', {
      category: TRACKER_CATEGORY_SINGLE_BOOKING_DETAILS,
    });
  };

  const handleCancel = (): void => {
    setIsEditing(false);
    setNote(booking.heroNote);

    tracker.track('Pressed Edit Booking Note Cancel', {
      category: TRACKER_CATEGORY_SINGLE_BOOKING_DETAILS,
    });
  };

  return isPastBooking ? (
    <div className="single-booking__booking-note-form">
      <h3>{t('single-booking_note-form-title')}</h3>

      {!isEditing && (
        <div className="clearfix">
          <textarea
            className="form-control"
            defaultValue={note}
            onClick={handleStartEditing}
            readOnly
            rows={6}
          />
          <br />
          <button className="button button--blue button--full-width-mobile" onClick={handleStartEditing} type="button">
            {isUpdating ? t('single-booking_saving-button-text') : t('single-booking_note-form-edit-button')}
          </button>
        </div>
      )}

      {isEditing && (
        <Formsy onValidSubmit={onSubmit}>
          <textarea
            className="form-control"
            name="heroNote"
            onChange={onChange}
            id="note"
            rows={6}
            value={note}
          />
          <br />
          {isUpdateError && (
            <div className="form__error text-center">
              {t('single-booking_unknown-error')}
            </div>
          )}
          <button
            className="button button--blue button--full-width-mobile"
            disabled={!booking.heroNote && !note}
            type="submit"
          >
            {isUpdating ? t('single-booking_saving-button-text') : t('single-booking_save-button')}
          </button>
          {booking.heroNote && (
            <button
              className="single-booking__cancel-button button button--white-btn button--full-width-mobile"
              onClick={handleCancel}
              type="button"
            >
              {t('single-booking_note-form-cancel-button')}
            </button>
          )}
        </Formsy>
      )}
      {isUpdating && <LoadingIndicator />}
    </div>
  ) : null;
});

export default withTranslation('single-booking')(BookingNoteForm);
