import React, { FC } from 'react';

import { StoreType } from '../stores/store';

const storeContext = React.createContext<StoreType | null>(null);

interface StoreProviderProps {
  value: StoreType;
}

export const StoreProvider: FC<StoreProviderProps> = ({ children, value }) => {
  return <storeContext.Provider value={value}>{children}</storeContext.Provider>;
};

export const useStore = (): StoreType => {
  const store: StoreType = React.useContext(storeContext);
  if (!store) {
    throw new Error('useStore must be used within a StoreProvider.');
  }
  return store;
};
