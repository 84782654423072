import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import { action, computed, IReactionDisposer, observable } from 'mobx';
import { observer } from 'mobx-react';

import cn from 'classnames';
import autobind from 'autobind-decorator';

import { withTranslation, WithTranslationType } from '../server/i18n';

interface Props extends WithTranslationType {
  className: string;
  getErrorMessage?: Function;
  getErrorMessages?: Function;
  id: string;
  isFormSubmitted?: boolean;
  labelText: string;
  name: string;
  onChange: Function;
  required?: boolean;
  setValue?: Function;
  showPasswordButton: boolean;
  type: string;
  value: string;
}

@observer
export class FormField extends Component<Props, any> {
  listeners: IReactionDisposer[];

  @observable errorMessage = '';
  @observable passwordIsHidden = true;

  static defaultProps = {
    className: 'grey-input',
    showPasswordButton: false,
    type: 'text',
    value: '',
  };

  componentWillReceiveProps(props): void {
    this.setErrorMessage(props.isFormSubmitted() && props.getErrorMessage());
  }

  @computed get type(): string {
    const { showPasswordButton, type } = this.props;
    return showPasswordButton && !this.passwordIsHidden ? 'text' : type;
  }

  @action('setErrorMessage - FormField')
  setErrorMessage(errorMessage: string): void {
    const { onChange } = this.props;
    this.errorMessage = errorMessage;

    if (onChange) {
      onChange(null, !!errorMessage);
    }
  }

  @autobind
  @action('toggleShowPasswordButton - FormField')
  toggleShowPasswordButton(): void {
    this.passwordIsHidden = !this.passwordIsHidden;
  }

  @autobind
  @action('changeValue - FormField')
  changeValue(event: React.ChangeEvent<HTMLInputElement>): void {
    const { errorMessage, props } = this;

    if (props.onChange) {
      props.onChange(event, !!errorMessage);
    }
    props.setValue(event.currentTarget.value);
  }

  render(): JSX.Element {
    const { changeValue, errorMessage, passwordIsHidden, toggleShowPasswordButton, type } = this;
    const { className, id, labelText, name, required, showPasswordButton, t, value } = this.props;

    return (
      <div className={className}>
        <input
          autoComplete={name}
          data-qa={`${name}-input`}
          data-testid={id}
          id={id}
          name={name}
          onChange={changeValue}
          required={required}
          spellCheck={false}
          type={type}
          value={value}
        />
        <label
          className={cn({ active: value })}
          data-qa={`${name}-label`}
          data-testid={`${id}-label`}
          htmlFor={id}
          id={`${id}-label`}
        >
          {labelText}
        </label>
        {showPasswordButton && (
          <span className="input-btn" onClick={toggleShowPasswordButton}>
            {passwordIsHidden ? t('form_show-password') : t('form_hide-password')}
          </span>
        )}
        {errorMessage && (
          <div
            className="form__error"
            data-qa={`${name}-error`}
            data-testid={`${id}-error`}
            id={`${id}-error`}
          >
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default withFormsy(withTranslation('form')(FormField));
