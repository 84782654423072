import React, { Fragment } from 'react';

import RadioButton from '../../components/RadioButton';

import { TransportTypeEnum } from '../../helpers/enums';
import { WithTranslationType, withTranslation } from '../../server/i18n';

interface Props extends WithTranslationType {
  errors: {
    transportType: boolean;
  };
  setTransportType: Function;
  transportType: string;
}

export const TransportType = ({
  errors,
  setTransportType,
  t,
  transportType,
}: Props): JSX.Element => (
  <Fragment>
    <div className="column is-12">
      <p className="is-hidden-tablet">{t('signup_transport-type-label')}</p>
      <div className="row-mobile pull-right">
        <RadioButton
          checked={transportType === TransportTypeEnum.PUBLIC_TRANSPORT}
          className="half-width-mobile"
          id="js-transport-type-public-transport"
          name="transportType"
          onClick={setTransportType}
          value={TransportTypeEnum.PUBLIC_TRANSPORT}
        >
          {t('signup_transport-type-public-transport')}
        </RadioButton>
        <RadioButton
          checked={transportType === TransportTypeEnum.DRIVING}
          className="half-width-mobile"
          id="js-transport-type-driving"
          name="transportType"
          onClick={setTransportType}
          value={TransportTypeEnum.DRIVING}
        >
          {t('signup_transport-type-driving')}
        </RadioButton>
      </div>
      <p className="is-hidden-mobile signup-form__pull-left-question">
        {t('signup_transport-type-label')}
      </p>
      {errors.transportType && (
        <div className="signup__error" data-qa="transport-type-error" id="js-transport-type-error">
          <span>{t('signup_required-field-error')}</span>
        </div>
      )}
    </div>
    <hr />
  </Fragment>
);

export default withTranslation('signup')(TransportType);
