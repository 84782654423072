/* eslint-disable no-param-reassign, no-restricted-syntax, consistent-return */
import { getRoot, flow, types, Instance } from 'mobx-state-tree';
import QS from 'qs';
import { getTreatmentsAndVerticals } from '../helpers/treatmentsAndVerticals';
import { Store } from './store';

import { TRACKER_CATEGORY_CREATE_ACCOUNT } from '../helpers/trackerConstants';
import { IApplicationFormData } from '../../types/signup';

export const STATUS_CREATING = 'creating';
export const STATUS_IN_PROGRESS = 'in-progress';
export const STATUS_ACCEPTED = 'accepted';
export const STATUS_DECLINED = 'declined';
export const STATUS_PROVIDER_CREATION_FAILED = 'provider-creation-failed';

export type ApplicationStatus =
  | typeof STATUS_CREATING
  | typeof STATUS_IN_PROGRESS
  | typeof STATUS_ACCEPTED
  | typeof STATUS_DECLINED
  | typeof STATUS_PROVIDER_CREATION_FAILED;

export const LEGACY_APPLICATION = {
  DECLINED: 'declined',
  IN_REVIEW: 'in-review',
  INTERVIEW: 'interview',
  ON_BOARDING: 'on-boarding',
  PENDING: 'pending',
  SUBMITTED: 'submitted',
  SUCCESSFUL: 'successful',
};

type ResponseDataType = {
  status: ApplicationStatus;
  redirectUrl: string;
};

export const Application = types
  .model('Application', {
    redirectUrl: types.maybe(types.maybeNull(types.string)),
    status: types.maybe(
      types.maybeNull(
        types.enumeration<ApplicationStatus>('ApplicationStatus', [
          'creating',
          'in-progress',
          'accepted',
          'declined',
          'provider-creation-failed',
        ]),
      ),
    ),
  })
  .views(() => ({}))
  .actions(self => {
    const rootStore = getRoot(self) as Instance<typeof Store>;
    const { tracker } = rootStore;

    const setApplicationStatusError = (): void => {
      self.status = STATUS_PROVIDER_CREATION_FAILED;
    };

    const submitApplication = flow(function* submitApplication(data: IApplicationFormData) {
      const { city } = rootStore.session;
      if (!city || !city.id) throw new Error('No city ID');
      const { treatments, verticalIds } = getTreatmentsAndVerticals(rootStore, city.id, data);

      const requestData = {
        gender: data.gender,
        treatments: treatments.join(','),
        vertical_ids: verticalIds,
        transportMode: data.transportType,
        preferredHours: {
          afternoons: data.preferredHours.afternoons,
          evenings: data.preferredHours.evenings,
          mornings: data.preferredHours.mornings,
          weekdays: data.preferredHours.weekdays,
          weekends: data.preferredHours.weekends,
        },
      };

      try {
        const res: Response = yield rootStore.sdk.postRequest(
          `/submit-application?${QS.stringify({
            sessionIdentifier: rootStore.session.token,
          })}`,
          requestData,
          {},
        );
        const responseData = yield res.json();
        if (responseData.status === 'OK') {
          tracker.track('Submit application success', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
          });
        } else {
          tracker.track('Submit application error', {
            category: TRACKER_CATEGORY_CREATE_ACCOUNT,
            message: 'applcation submit failed',
          });
        }

        return responseData;
      } catch (error) {
        tracker.track('Submit application error', {
          category: TRACKER_CATEGORY_CREATE_ACCOUNT,
          message: error,
        });
      }
    });

    const getApplicationStatus = flow(function* getApplicationStatus() {
      self.redirectUrl = null;

      try {
        const res: Response = yield rootStore.sdk.getRequest(
          `/application-status?${QS.stringify({
            sessionIdentifier: rootStore.session.token,
          })}`,
          {},
        );

        if (!res.ok) {
          const error = yield res.json();

          throw error;
        }

        const responseData: ResponseDataType = yield res.json();

        if (responseData) {
          const { redirectUrl, status } = responseData;

          if (status) {
            self.status = status;
          } else {
            setApplicationStatusError();
          }

          if (redirectUrl) {
            self.redirectUrl = redirectUrl;
          }
        }
      } catch (error) {
        setApplicationStatusError();

        tracker.track('Get application status error', {
          category: 'submit-application',
          message: error,
        });
      }
    });

    const getPostQuizStatus = flow(function* getPostQuizStatus(
      applicantId: string,
      quizId: string,
    ) {
      self.redirectUrl = null;

      if (!applicantId || !quizId) {
        setApplicationStatusError();
        return;
      }

      try {
        const res: Response = yield rootStore.sdk.getRequest(
          `/application-status/${applicantId}?${QS.stringify({
            quizId,
          })}`,
          {},
        );

        if (!res.ok) {
          const error = yield res.json();

          throw error;
        }

        const responseData: ResponseDataType = yield res.json();

        if (responseData) {
          const { redirectUrl } = responseData;

          if (redirectUrl) {
            self.redirectUrl = redirectUrl;
          }
        }
      } catch (error) {
        setApplicationStatusError();

        tracker.track('Get post quiz redirect url error', {
          category: 'status-check',
          message: error.message,
        });
      }
    });

    return {
      getApplicationStatus,
      getPostQuizStatus,
      setApplicationStatusError,
      submitApplication,
    };
  });
