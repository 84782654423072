import React, { FC, useEffect, ReactNode } from 'react';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useStore } from '../../contexts/storeContext';
import { withTranslation, WithTranslationType } from '../../server/i18n';

import ErrorMessage from './ErrorMessage';
import LoadingIndicator from './LoadingIndicator';
import SingleBookingHeader from './SingleBookingHeader';
import SingleBookingSummary from './SingleBookingSummary';
import SingleBookingOtherHeroes from './SingleBookingOtherHeroes';
import SingleBookingMapSection from './SingleBookingMapSection';
import SingleBookingNotes from './SingleBookingNotes';
import VideoCallList from './VideoCallList';

import './single-booking.scss';

const wrapWithContainer = (elem: ReactNode): JSX.Element => (
  <div className="single-booking portal-screen-container columns is-multiline is-mobile">
    {elem}
  </div>
);

const SingleBooking: FC<WithTranslationType> = observer(({ t }: WithTranslationType) => {
  const {
    singleBooking: { booking, isBookingNotFound, isCancelled, isLoading, isError, loadBooking },
  } = useStore();
  const { query } = useRouter();

  useEffect(() => {
    if (query && query.id) {
      loadBooking(query.id);
    }
  }, []);

  if (isLoading) {
    return wrapWithContainer(<LoadingIndicator className="single-booking__container" />);
  }

  if (isError) {
    return wrapWithContainer(
      <ErrorMessage className="single-booking__container">
        {t('single-booking_unknown-error')}
      </ErrorMessage>,
    );
  }

  if (isBookingNotFound) {
    return wrapWithContainer(
      <ErrorMessage className="single-booking__container">
        {t('single-booking_booking-not-found', { bookingId: query && query.id })}
      </ErrorMessage>,
    );
  }

  if (booking && isCancelled) {
    return wrapWithContainer(
      <ErrorMessage className="single-booking__container">
        {t('single-booking_error-cancelled')}
      </ErrorMessage>,
    );
  }

  if (booking) {
    return wrapWithContainer(
      <>
        <section className="single-booking__section column is-10 is-offset-1">
          <div className="columns">
            <div className="column is-12-mobile is-6-tablet">
              <SingleBookingHeader />
              <SingleBookingSummary />
              <SingleBookingOtherHeroes />
              <VideoCallList />
            </div>
            <SingleBookingMapSection />
          </div>
        </section>
        <SingleBookingNotes />
      </>,
    );
  }

  return wrapWithContainer(
    <ErrorMessage className="single-booking__container">
      {t('single-booking_booking-not-found')}
    </ErrorMessage>,
  );
});

export default withTranslation('single-booking')(SingleBooking);
