import React, { FC } from 'react';
import cn from 'classnames';

import './loading-indicator.scss';

interface Props {
  className?: string;
  style?: {
    [key: string]: string,
  };
}

const LoadingIndicator: FC<Props> = ({ className, style }: Props) => {
  return (
    <div className={cn('loading-indicator__spinner', className)} style={style}>
      <div className="loading-indicator__bounce1" />
      <div className="loading-indicator__bounce2" />
      <div className="loading-indicator__bounce3" />
    </div>
  );
};

export default LoadingIndicator;
