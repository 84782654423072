import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { computed } from 'mobx';

import cn from 'classnames';

import Spinner from '../../components/Spinner';

interface Props {
  errors: any;
  loading: boolean;
  t: (param: string) => string;
}

@observer
class SubmitButton extends Component<Props, null> {
  @computed get hasErrors(): boolean {
    return Object.values(this.props.errors).some(i => i);
  }

  render(): JSX.Element {
    const { hasErrors } = this;
    const { errors, loading, t } = this.props;
    let errorMessage: string | JSX.Element;

    if (errors.serverErrorCode === 'LOGIN_DETAILS_NOT_RECOGNISED_ERROR') {
      errorMessage = t('login_incorrect-credentials');
    } else {
      errorMessage = t('login_error-occurred-while-log-in');
    }

    return (
      <div className="login-section__submit-btn-wrapper">
        <div className="text-center">
          <button
            className={cn('button button--submit-btn full-width-mobile', {
              disabled: loading || hasErrors,
              loading: loading && !hasErrors,
            })}
            data-qa="login-submit-button"
            data-testid="js-login-submit-button"
            formNoValidate
            id="js-login-submit-button"
            type="submit"
          >
            {loading && !hasErrors ?
              <Spinner />
              :
              <span>{t('login_submit')}</span>
            }
          </button>
        </div>
        {hasErrors && (
          <div
            className="form__error-box"
            data-qa="error-box-message"
            data-testid="js-error-box-message"
          >
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
}

export default SubmitButton;
