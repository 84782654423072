import React, { Fragment } from 'react';

import RadioButton from '../../components/RadioButton';
import { GenderEnum } from '../../helpers/enums';
import { WithTranslationType, withTranslation } from '../../server/i18n';

interface Props extends WithTranslationType {
  errors: {
    gender: boolean;
  };
  gender: string;
  setGender: Function;
}

export const SelectGender = ({ errors, gender, setGender, t }: Props): JSX.Element => (
  <Fragment>
    <div className="column is-12">
      <p className="is-hidden-tablet">{t('signup_gender-label')}</p>
      <div className="row-mobile pull-right">
        <RadioButton
          checked={gender === GenderEnum.FEMALE}
          className="half-width-mobile"
          id="js-gender-female"
          name="gender"
          onClick={setGender}
          value={GenderEnum.FEMALE}
        >
          {t('signup_gender-female')}
        </RadioButton>
        <RadioButton
          checked={gender === GenderEnum.MALE}
          className="half-width-mobile"
          id="js-gender-male"
          name="gender"
          onClick={setGender}
          value={GenderEnum.MALE}
        >
          {t('signup_gender-male')}
        </RadioButton>
      </div>
      <p className="is-hidden-mobile signup-form__pull-left-question">{t('signup_gender-label')}</p>
      {errors.gender && (
        <div
          className="signup__error"
          data-qa="gender-error"
          data-testid="gender-error"
          id="js-gender-error"
        >
          <span>{t('signup_required-field-error')}</span>
        </div>
      )}
    </div>
    <hr />
  </Fragment>
);

export default withTranslation('signup')(SelectGender);
