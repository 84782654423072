import { inject, observer } from 'mobx-react';
import { Instance } from 'mobx-state-tree';
import React, { FC, useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { useRouter } from 'next/router';
import CenteredApplicationSection from '../../components/application/CenteredApplicationSection';
import ApplicationScreenTitle from '../../components/application/ApplicationScreenTitle';
import ApplicationScreenSubtitle from '../../components/application/ApplicationScreenSubtitle';
import MessageBox from '../../components/MessageBox';
import { withTranslation, WithTranslationType } from '../../server/i18n';
import { I18nUtils } from '../../stores/i18nUtils';
import { PartnershipAgreement } from '../../stores/session/partnership-agreement';
import LoadingIndicator from '../single-booking/LoadingIndicator';

import config from '../../helpers/config';

import './sign-agreement-form.scss';
import isValidException from '../../helpers/isValidException';

const { HELLO_SIGN_CLIENT_ID, NODE_ENV } = config.publicRuntimeConfig;

const ON_SIGNED_TIMEOUT = 3000;

const SignAgreementForm: FC<{
  i18nUtils: Instance<typeof I18nUtils>;
  onSigned: () => Promise<void>;
  partnershipAgreement: Instance<typeof PartnershipAgreement>;
} & WithTranslationType> = ({ i18nUtils, onSigned, partnershipAgreement, t }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isAlreadySigned, setIsAlreadySigned] = useState(false);

  const helloSignElem = useRef(null);
  let timeout: ReturnType<typeof setTimeout>;

  useEffect(() => {
    partnershipAgreement
      .getSignatureRequest()
      .then(() => {
        setIsSuccess(true);
        setIsLoading(false);
      })
      .catch(err => {
        // Shouldn't get here - but a fallback in case logic in redirects fails
        if (err.message && /has already been signed/.test(err.message)) {
          setIsError(true);
          setIsAlreadySigned(true);
          timeout = setTimeout(() => {
            router.push(`/${i18nUtils.locale}/bookings`);
          }, ON_SIGNED_TIMEOUT);

          return;
        }
        // Unexpected hellosign error
        if (isValidException(err)) {
          Sentry.captureException(err);
        } else {
          Sentry.captureMessage(err.message || 'unexpected hellosign error');
        }
        setIsError(true);
        setIsLoading(false);
      });

    return (): void => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  useEffect(() => {
    let client;
    if (isSuccess) {
      import('hellosign-embedded').then(module => {
        // hellosign module depends on window existing - get around the webpack error this causes - loading the component ssr false alone is not enough
        /* eslint-disable new-cap */
        client = new module.default({
          clientId: HELLO_SIGN_CLIENT_ID,
          allowCancel: false,
          container: helloSignElem.current,
          debug: NODE_ENV === 'development',
          height: 800,
          locale: i18nUtils.locale,
          skipDomainVerification: NODE_ENV === 'development',
        });

        client.open(partnershipAgreement.docUrl);
        client.on('sign', onSigned);
        /* eslint-enable new-cap */
      });
    }

    return (): void => {
      if (client) {
        client.off('sign', onSigned);
        client.close();
      }
    };
  }, [isSuccess]);

  /* eslint-disable react/no-danger */
  return (
    <>
      <CenteredApplicationSection>
        <ApplicationScreenTitle>{t('sign-agreement_title')}</ApplicationScreenTitle>
        <ApplicationScreenSubtitle>{t('sign-agreement_subtitle')}</ApplicationScreenSubtitle>

        {isLoading && <LoadingIndicator />}
        {isError && isAlreadySigned && (
          <MessageBox text={t('sign-agreement_agreement-already-complete')} type="error" />
        )}
        {isError && !isAlreadySigned && (
          <MessageBox text={t('sign-agreement_signature-request-failure')} type="error" />
        )}
      </CenteredApplicationSection>
      <div className="sign-agreement-form__doc-container" ref={helloSignElem} />
      <style
        dangerouslySetInnerHTML={{
          __html: '.x-hellosign-embedded { width: 100%!important; height: auto!important; }',
        }}
      />
    </>
  );
  /* eslint-enable react/no-danger */
};

export default withTranslation('sign-agreement')(
  inject(({ store: { i18nUtils, partnershipAgreement } }) => ({
    i18nUtils,
    partnershipAgreement,
  }))(observer(SignAgreementForm)),
);
