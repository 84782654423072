import React, { FC } from 'react';
import { observer } from 'mobx-react';

import CityInput from '../../components/CityInput';

import chevron from '../../../public/static/images/icons/chevron.png';

interface Props {
  city: string;
  setCity: Function;
  t: (param: string) => string;
}

const City: FC<Props> = ({ city, setCity, t }) => {
  return (
    <div className="signup-form__city-box">
      <label className="active" htmlFor="select-city">
        {t('signup_city-select-label')}
      </label>
      <CityInput
        className="signup-form__city"
        data-qa="signup-form-city"
        name="city"
        placeholder={t('signup_city-select')}
        setValue={setCity}
        value={city}
      />
      <img alt="arrow" className="signup-form__arrow" src={chevron} />
    </div>
  );
};

export default observer(City);
