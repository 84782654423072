import React, { FC } from 'react';
import cn from 'classnames';
import Link from '../../Link';
import { WithTranslationType, withTranslation } from '../../../server/i18n';
import { useStore } from '../../../contexts/storeContext';

import config from '../../../helpers/config';

const { publicRuntimeConfig } = config;
const { HTTP_HOST, SALESFORCE_FAQ_BASE_URL } = publicRuntimeConfig;

interface MenuProps extends WithTranslationType {
  activeMenuItem: string;
  hideCommunity: boolean;
  hideReferral: boolean;
  hideStatements: boolean;
}

const Menu: FC<MenuProps> = ({
  activeMenuItem,
  hideCommunity,
  hideReferral,
  hideStatements,
  t,
}: MenuProps) => {
  const { config, i18nUtils } = useStore();

  const communityLinkActive = config.getValue(
    'hero.feature.show-community-link',
    i18nUtils.umRegion,
  );
  const communityLinkUrl = config.getValue('hero.feature.community-url', i18nUtils.umRegion);
  const educationPortal = config.getValue('hero.has-education-portal', i18nUtils.umRegion);
  const promoteProfileActive = config.getValue(
    'hero.feature.promote-profile.enabled',
    i18nUtils.umRegion,
  );
  const promotedReviewsActive = config.getValue(
    'hero.feature.promoted-reviews.enabled',
    i18nUtils.umRegion,
  );

  return (
    <nav>
      <ul className="portal-navbar__menu hidden-xs">
        <li className="portal-navbar__menu-item">
          <Link
            className={cn(
              'portal-navbar__menu-link',
              activeMenuItem === 'bookings' && 'portal-navbar__menu-link--active',
            )}
            prefetch={false}
            route="/bookings"
          >
            {t('portal-navbar_bookings')}
          </Link>
        </li>
        <li className="portal-navbar__menu-item">
          <Link
            className={cn(
              'portal-navbar__menu-link',
              activeMenuItem === 'diary' && 'portal-navbar__menu-link--active',
            )}
            prefetch={false}
            route="/diary"
          >
            {t('portal-navbar_diary')}
          </Link>
        </li>
        <li className="portal-navbar__menu-item">
          <a
            className={cn(
              'anchor portal-navbar__menu-link',
              activeMenuItem === 'perks' && 'portal-navbar__menu-link--active',
            )}
            href={`https://${HTTP_HOST}/perks`}
          >
            {t('portal-navbar_perks')}
          </a>
        </li>
        {!hideReferral ? (
          <li className="portal-navbar__menu-item">
            <a
              className={cn(
                'anchor portal-navbar__menu-link',
                activeMenuItem === 'referral' && 'portal-navbar__menu-link--active',
              )}
              href={`https://${HTTP_HOST}/refer`}
            >
              {t('portal-navbar_referral')}
            </a>
          </li>
        ) : null}
        {!hideCommunity ? (
          <li className="portal-navbar__menu-item">
            <a
              className={cn(
                'anchor portal-navbar__menu-link',
                activeMenuItem === 'resources' && 'portal-navbar__menu-link--active',
              )}
              href={`https://${HTTP_HOST}/resources`}
            >
              {t('portal-navbar_resources')}
            </a>
          </li>
        ) : null}
        <li className="portal-navbar__menu-item">
          <a
            className={cn(
              'anchor portal-navbar__menu-link',
              activeMenuItem === 'account' && 'portal-navbar__menu-link--active',
            )}
            href={`https://${HTTP_HOST}/account`}
          >
            {t('portal-navbar_account')}
          </a>
          <div className="portal-navbar__sub-menu-container">
            <ul className="portal-navbar__sub-menu">
              <li className="portal-navbar__sub-menu-item">
                <a className="anchor portal-navbar__sub-menu-link" href="/account">
                  {t('portal-navbar_account-details')}
                </a>
              </li>
              {communityLinkActive && !hideCommunity ? (
                <li className="portal-navbar__sub-menu-item">
                  <a
                    className="anchor portal-navbar__sub-menu-link"
                    href={communityLinkUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('portal-navbar_account-community')}
                  </a>
                </li>
              ) : null}
              <li className="portal-navbar__sub-menu-item">
                <a className="anchor portal-navbar__sub-menu-link" href="/documents">
                  {t('portal-navbar_account-documents')}
                </a>
              </li>
              {promotedReviewsActive ? (
                <li className="portal-navbar__sub-menu-item">
                  <a className="anchor portal-navbar__sub-menu-link" href="/reviews">
                    {t('portal-navbar_account-reviews')}
                  </a>
                </li>
              ) : (
                <li className="portal-navbar__sub-menu-item">
                  <a className="anchor portal-navbar__sub-menu-link" href="/ratings">
                    {t('portal-navbar_account-ratings')}
                  </a>
                </li>
              )}

              <li className="portal-navbar__sub-menu-item">
                <Link
                  className={cn(
                    'portal-navbar__sub-menu-link',
                    activeMenuItem === 'service-areas' && 'portal-navbar__sub-menu-link--active',
                  )}
                  prefetch={false}
                  route="/service-areas"
                >
                  {t('portal-navbar_account-service-areas')}
                </Link>
              </li>
              {!hideStatements ? (
                <li className="portal-navbar__sub-menu-item">
                  <a className="anchor portal-navbar__sub-menu-link" href="/statements">
                    {t('portal-navbar_account-statements')}
                  </a>
                </li>
              ) : null}
              {promoteProfileActive ? (
                <li className="portal-navbar__sub-menu-item" ng-if="promoteProfileActive">
                  <a className="anchor portal-navbar__sub-menu-link" href="/promote-profile">
                    {t('portal-navbar_account-promote-profile')}
                  </a>
                </li>
              ) : null}
            </ul>
          </div>
        </li>
        <li className="portal-navbar__menu-item">
          <a
            className={cn(
              'anchor portal-navbar__menu-link',
              activeMenuItem === 'help' && 'portal-navbar__menu-link--active',
            )}
            href={
              i18nUtils.umRegion === 'UK' ? SALESFORCE_FAQ_BASE_URL : `https://${HTTP_HOST}/faq`
            }
          >
            {t('portal-navbar_help')}
          </a>
        </li>
        {educationPortal ? (
          <li className="portal-navbar__menu-item">
            <a className="anchor portal-navbar__menu-link" href={`https://${HTTP_HOST}/education`}>
              {t('portal-navbar_education-portal')}
            </a>
          </li>
        ) : null}
      </ul>
    </nav>
  );
};

export default withTranslation('portal-navbar')(Menu);
