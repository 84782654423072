import React, { FunctionComponent, ReactElement } from 'react';

import cn from 'classnames';

interface Props {
  checked: boolean;
  children: ReactElement | string;
  className?: string;
  id: string;
  name: string;
  onClick: Function;
  value: string;
}

const RadioButton: FunctionComponent<Props> = (props: Props) => {
  const { checked, children, className, id, name, onClick, value } = props;

  return (
    <div className={cn('green-radio', className)}>
      <input
        checked={checked}
        className="form-control"
        data-qa={`${name}-${value}-input`}
        data-testid={id}
        id={id}
        name={name}
        onChange={() => onClick(value)}
        type="radio"
        value={value}
      />

      <label
        data-qa={`${name}-${value}-label`}
        data-testid={`${id}-label`}
        htmlFor={id}
        id={`${id}-label`}
      >
        <div className="text-container">{children}</div>
      </label>
    </div>
  );
};

export default RadioButton;
